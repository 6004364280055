import { createSlice } from "@reduxjs/toolkit";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    calibrationTime: 0,
    tmpSessionId: "",
  },
  reducers: {
    setCalibrationTime: (state) => {
        state.calibrationTime = Date.now()
    },
    setTmpSessionId: (state, action: {
      payload: string;
      type: string;
    }) => {
      state.tmpSessionId = action.payload
    }
  },
});

export const { setCalibrationTime, setTmpSessionId } = sessionSlice.actions;

export default sessionSlice.reducer;
