import React, { useRef } from "react";
import { useAppDispatch } from "../../hooks/store";
import { logIn } from "../../store/reducers/auth";
import { LoginFailure, loginRequest } from "../../api";
import ErrorFormatter from "../../utils/errorFormat";

const Login = () => {
  const dispatch = useAppDispatch();
  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const errorRef = useRef<HTMLSpanElement>(null);
  const sendLogIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (errorRef.current?.textContent) errorRef.current.textContent = null;
    let un = usernameRef?.current?.value;
    let pw = passwordRef?.current?.value;
    if (un && pw) {
      try {
        let res = await loginRequest(un, pw);
        dispatch(logIn(res));
      } catch (e) {
        if (errorRef.current) errorRef.current.textContent = ErrorFormatter.formatError(e)
      }
    }
    // TODO: HANDLE EMPTY USERNAME ERROR
  };
  return (
    <div className="lg:w-1/2 lg:p-10">
      <form className="lg:w-1/2" onSubmit={sendLogIn}>
        <p className="text-lg mb-5">The Focus Project</p>
        <span ref={errorRef} className="block text-red-500 text-sm mb-2"></span>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Email address
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Username"
            ref={usernameRef}
          />
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            ref={passwordRef}
          />
        </div>
        <div className="flex items-center justify-content gap-9">
          <button
            className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Log In
          </button>
          <a
            className="inline-block align-baseline text-sm text-primary hover:font-bold"
            href="#"
          >
            Forgot Password?
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
