import React from "react";
import Login from "../components/login/login";
import Session from "../components/session/session";
import { useAppSelector } from "../hooks/store";

const Home = () => {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  return (
    <div className="bg-gray-50 h-3/5 lg:h-full p-8 lg:px-16 lg:col-span-2 flex justify-center border-l border-t">
      { !loggedIn ? <Login /> : <Session /> }
    </div>
  );
};

export default Home;
