import { createRoot } from "react-dom/client";
import { App } from "./App";
import React from "react";
import {store, persistor} from "./store/store";
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";

const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
