import React from "react";
import { endWildRequest, submitWildValidationRequest } from "../../../api";
import { ValidationResult } from "../../../types/rni";
import { SessionValidationProps, SessionState } from "../session";
import ErrorFormatter from "../../../utils/errorFormat";

export const SessionReturn = ({
  broker,
  dispatch,
  validationRef,
  validationSessionDetails,
  trackerRef,
  setSessionState,
  errorHandle
}: SessionValidationProps) => {
  const currentDate = new Date();
  const sessionEndDate = new Date(
    currentDate.setMinutes(currentDate.getMinutes() + 10)
  );

  const validate = async () => {
    try {
      let div = validationRef.current;
      let trackerDiv = trackerRef.current;
      if (!div || !trackerDiv) throw "Unable to reference calibration div";
      await broker.init(30000, div);
      broker.loadModel("model", "errorCorrection");
      broker.trackersController._videoView.releaseDivElement();
      let validation: ValidationResult = await broker.validate(div, 50000);
      broker.turnOffCamera();
      await submitWildValidationRequest(
        validation,
        validationSessionDetails.panelist,
        validationSessionDetails.panelistId,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.calibrationTime
      );
      await endWildRequest(
        validationSessionDetails.accessCode,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.panelistId
      );
      setSessionState(SessionState.COMPLETE);
      // TODO: RENDER END SESSIONS PAGE
    } catch (e) {
      let div = validationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      errorHandle(ErrorFormatter.formatError(e));
    }
  };

  return (
    <>
      <p className="font-semibold mb-10">Validation</p>
      <p className="mb-10">We need to do an end of session validation.</p>
      <p className="mb-10">
        To validate we will ask you to look at another dot.
      </p>
      <p className="mb-10">
        On the next page you will see a green validation dot. Please follow it
        with your eyes as it moves across the screen.
      </p>
      <p className="mb-10">Try to keep your head still.</p>
      <button
        onClick={validate}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Continue
      </button>
    </>
  );
};
