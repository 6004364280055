import React from "react";
import { Navbar } from "./components/navbar/navbar";
import { useAppSelector } from "./hooks/store";
import { Outlet } from "react-router-dom";

export default function Root(_props) {
  const loggedIn = useAppSelector((state) => state.auth.loggedIn);
  return (
    <>
      {loggedIn ? <Navbar /> : null}
      <main className={`relative ${loggedIn ? "h-main": "h-screen"}`}>
        <Outlet />
      </main>
    </>
  );
}
