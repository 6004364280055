import React from "react";

const Privacy = () => {
  return (
    <div className="bg-gray-50 h-3/5 lg:h-fit p-8 lg:px-16 lg:col-span-2 flex justify-center border-l border-t">
      <div className="lg:w-1/2 lg:p-10">
        <section className="mb-10">
          <h1 className="font-semibold mb-10 text-2xl">
            {" "}
            Focus Project Research - Privacy Policy{" "}
          </h1>
          <p className="font-semibold mb-10">Last Updated: 1 Mar 2022</p>
          <p>
            You are being asked for your consent to take part in a research
            study. This document provides a concise summary of this research. It
            describes the key information that we believe most people need to
            decide whether to take part in this research. This Privacy Policy
            also describes the types of information collected, as well as how we
            use and disclose such information.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What should I know about this research?
          </p>
          <ul>
            <li>
              This document will explain this research (called 'the Focus
              Project') to you.
            </li>
            <li>
              Taking part in this research is voluntary. Whether you take part
              is up to you.
            </li>
            <li>If you don't take part, it won't be held against you.</li>
            <li>
              You can take part now and later drop out, and it won't be held
              against you
            </li>
            <li>If you don't understand, ask questions.</li>
            <li>Ask all the questions you want before you decide.</li>
          </ul>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            How long will I be in this research?
          </p>
          <p>
            We expect that your taking part in this research will last at least
            around 6 weeks, but it could be extended. You can participate until
            either you or we terminate your involvement with the project, or the
            project comes to an end. You have full control over your
            participation and are able to participate as much or as little as
            you like once you have signed up. You simply receive points if you
            participate and not if you don't.{" "}
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            Why is this research being done?
          </p>
          <p>
            The purpose of this research is to help understand how attention
            works online and how individuals view and interact with online media
            (e.g., websites and online advertising).
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What happens to me if I agree to take part in this research?
          </p>
          <p>
            If you agree to take part you will need to install the Focus Project
            Software - either an app for your smartphone, or a Chrome browser
            extension if taking part on a laptop or desktop. Once you have
            signed up, you can earn points for participating in the research.
            You can do this in two ways:
          </p>
          <ul>
            <li>
              By undertaking 'Browsing Sessions': spending time browsing the web
              freely, within the app (on any sites of your choosing) whilst
              Lumen's eye tracking is active.
            </li>
            <li>
              By completing specific 'Tasks': these usually involve simply being
              directed to and asked to browse on specific sites, whilst Lumen's
              eye tracking is active.
            </li>
          </ul>
          <p>
            Prior to participation in any Browsing Session or Task, you will
            need to run through a calibration exercise, so you know the eye
            tracking is being activated and working properly.
          </p>
          <p>
            All of the above activities can be completed from your own home (or
            anywhere with an internet connection). Participation in tasks and
            browsing is encouraged and incentivised, but always optional.{" "}
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            Could being in this research hurt me?
          </p>
          <p>
            No, there are no risks or discomforts that you may expect from
            taking part in this research that are any different to anything you
            would experience through the everyday activity of browsing the
            internet. Taking part in the research imposes a minor privacy risk
            as details of the web browsing you undertake whilst participating in
            the research will be collected. However, you should be aware that
            there are several ways in which these risks are mitigated. Your
            participation is completely anonymous. Furthermore, you will have
            full control over the web browsing you undertake and can decide to
            stop the collection of data in the Focus Project at any time by
            simply closing or turning off the Focus Project Software.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What else should I know about this research?
          </p>
          <p>
            Other information that may be important for you to consider so you
            can decide whether to take part in this research is that when you
            are participating in the Focus Project, information is collected
            about which sites you are browsing on, the contents of those sites
            and which elements the eye tracking estimates you are looking at and
            for how long. Information is collected about web browsing only when
            individuals are browsing inside the Focus Project App. No
            information about web browsing is collected from browsing in any
            other apps or web browsers. Activating the eye tracking requires
            that you give consent to the Focus Project software to access the
            front-facing camera on your device, but you should know that no
            images from the camera are captured or stored in order to collect
            the eye tracking data.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            Will I be paid for taking part in this research?
          </p>
          <p>
            You will be paid for taking part in this research. Participants earn
            points for their participation that are redeemable into cash. For
            details, please refer to the associated Focus Project Terms and
            Conditions document which will explain the basis on which you can
            earn points and how these can be redeemed.
          </p>
          <p>
            The data collected through this research may be used for commercial
            profit. You will not share in this commercial profit.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What happens if I agree to be in this research, but I change my mind
            later?
          </p>
          <p>
            If you decide to leave this research, contact the research team so
            that they can terminate your participation in the Focus Project
            without penalty.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">Statement of Consent:</p>
          <p>
            Your acceptance of this consent document through the Focus Project
            online recruitment process documents your consent to take part in
            this research. Consent can only be given by an adult subject capable
            of consent.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            Who is running this 'Focus Project' research?
          </p>
          <p>
            The research is being run by Lumen Research Limited ("Lumen")
            ("us/we" and "the Focus Project Team").
          </p>
          <p>
            Lumen Research Ltd ("Lumen") is a company registered in England,
            company number 8682432. Its registered office is at; Unit 215, 22
            Highbury Grove, London N5 2EF, United Kingdom. Its data protection
            registration reference is ZA130772.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What consent am I giving to the use of data collected during the
            Research?
          </p>
          <p>
            By installing the software, you consent to our collection, use and
            disclosure of your information as described in this Privacy Policy.
            You can also withdraw your consent to our future collection of your
            information by contacting us using the information provided in the
            Contacting Us section of this Privacy Policy or by uninstalling the
            Software. Withdrawal of your consent will not apply retroactively to
            information that was previously collected with your consent.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What information will be collected?
          </p>
          <p>
            By participating in the Research, we will collect and use
            information about you. This information includes:
          </p>
          <ul>
            <li>your registration details;</li>
            <li>
              information on your web-browsing undertaken within the Focus
              Project Software ("Web-Browsing Data");
            </li>
            <li>
              information on the content of your web-browsing ("Web-Browsing
              Content Data");
            </li>
            <li>
              information on where your eyes are focusing on your screen when
              the eye tracking is enabled ("Gaze Data");
            </li>
            <li>
              information about your device that can optimise the performance of
              our Software including information about your operating system's
              name and version;
            </li>
            <li>
              your responses to specific surveys and activities administered as
              part of the project; and
            </li>
            <li>
              any other information that you give to use in participating in the
              Research; (together "Your Information").
            </li>
          </ul>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">How will the data be collected?</p>
          <p>
            Participation in the Research will require use, during your
            web-browsing, of Lumen's eye tracking technology. This will require
            the use of a front-facing camera on an internet-connected device.
            The eye tracking technology captures information about where your
            eyes are focusing on your device's screen over time.
          </p>
          <p>
            Participation will also require installation of Lumen's Software
            onto your device (each, a "Browsing Device"). You will be invited to
            participate either through the installation of the Focus Project App
            on a smartphone or tablet, or through the installation of a Chrome
            Extension on a desktop / laptop computer. In either case, the
            Software will enable Lumen to collect information related to
            internet browsing activity undertaken either within the App or
            within Chrome when the Extension is turned on. The Software also
            enables Lumen to associate the Gaze Data with your Web-Browsing Data
            (i.e., to see what you were looking at while you were browsing - see
            next question below).{" "}
          </p>
          <p>
            The Focus Project App collects data about browsing activity within
            the Focus Project App, but not within any other apps on your mobile
            device. Information on browsing undertaken in any other app will not
            be collected. Similarly, the Chrome Extension collects data about
            browsing activity within Chrome - and only when the Chrome Extension
            is turned on. Information on browsing undertaken in any other web
            browser will not be collected.{" "}
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What information do you collect about web browsing?
          </p>
          <p>
            Information is only collected about your web browsing when you are
            browsing inside the Focus Project App or in Chrome with the Focus
            Project Extension turned on. You have full control over the
            installed Software and will be able to stop a Browsing Session at
            any time.
          </p>
          <p>
            Web-Browsing Data that may be collected during a Browsing Session
            includes data about:
          </p>
          <ul>
            <li>
              The URLs of the websites you visit, the time of your visit, the
              amount of time you spend on each page and the Gaze Data on
              displayed elements;
            </li>
            <li>Cookies that your browser receives, sends or stores;</li>
            <li>
              Undisplayed data associated with some specific elements on the
              screen (e.g., the source of a served advertisement);
            </li>
            <li>
              Your interactions and clicks/taps on the web pages you visit; and
            </li>
            <li>
              Some of the content of your web-browsing (see next question below)
            </li>
          </ul>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What information do you collect about the content of my web
            browsing?
          </p>
          <p>
            On most sites, only the type of content that is on the page will be
            recorded and the eye tracking data associated with that content
            element (e.g., whether you were looking at a picture or a text box),
            but not the actual content (the specific image, or the actual text).
            This applies to social media and webmail sites so that your privacy
            is protected. Data is not collected about the content of your emails
            or social media posts.
          </p>
          <p>
            On some sites data will also be collected about the actual content
            of your web browsing. This is restricted to sites that are not
            deemed to contain any personal or sensitive data (e.g., news /
            information sites). Furthermore, content will never be collected
            from these sites whenever a "personal data identifier" is displayed
            (such as password entry fields or information that could indicate
            the presence of banking data). Content will also never be collected
            whenever any text is typed into any input field on the web page
            (e.g., a login box, a search bar, or any other data entry field).
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            What controls and protections do I have over the data collected?
          </p>
          <p>
            To summarise the key points above, the following controls are in
            place to protect your privacy during your participation in the
            Research:
          </p>
          <ul>
            <li>
              You are able to stop the collection of data in the Focus Project
              at any time by simply closing or turning off the Focus Project
              Software.
            </li>
            <li>
              Data is only collected when you are taking part in a Task or
              Browsing Session in the Focus Project App, or within Chrome with
              the Focus Project Extension turned on. No data will be collected
              relating to any browsing carried out in any other app or web
              browser.
            </li>
            <li>
              On most sites' information is only collected about the type of
              content viewed (e.g., whether it is text), not the actual content
              (e.g., not the actual text).
            </li>
            <li>
              On some sites actual content may be collected. On these sites,
              Web-Browsing Content Data will not take place if "personally
              identifiable information" is displayed or if any information is
              typed into a text input field.
            </li>
            <li>
              Gaze Data and Web-Browsing Data collected will be stored
              anonymously (i.e. it will not be stored alongside any personal
              details - name, address, phone number etc).
            </li>
            <li>
              You are able to terminate your participation in the Focus Project
              at any time.
            </li>
          </ul>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            How will Your Information be used?
          </p>
          <p>
            Your Information will be used to explore how individuals view and
            interact with online media. We may share the Research data with our
            affiliated companies and with our business partners and clients.
            While the Research data might be shared with our affiliates,
            partners, and clients, it is only used anonymously and such
            affiliates, partners and clients use this anonymised data to create
            summary descriptions of a wide range of consumer behaviours and
            consumer segments to develop products or to provide marketing
            solutions to their customers or consumers.
          </p>
          <p>
            We combine the information that we collect from your use of the
            Software and, where applicable, the Device, and inferences that we
            draw from that information, with information we have collected from
            or about you or other members of your household in connection with
            you or your household's participation in our online and offline
            research studies. We analyse and use Your Information for our
            research purposes at an aggregated level. We also use it to create
            reports for our clients. These reports do not identify you or any
            other person by name, address, telephone number, or other
            information that identifies you individually but may include
            information about your device usage and the operational information
            described above.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            Why do you use cookies and what are these used for?
          </p>
          <p>
            From time to time cookies may be used to carry out the Research and
            to make your experience better. A cookie is a piece of information
            which is transferred to the cookie file of the browser on your
            computer. The browser saves the information and sends it back to a
            website server whenever the browser returns to the website. Cookies
            may be deposited through your visits to particular websites or may
            be deposited directly by the Focus Project Software. The purpose of
            any cookies deposited will be (i) to identify the state of your
            browsing device e.g., whether or not the eye tracking device is
            connected and currently in operation and (ii) to enable specific
            test content to be served to you during your Focus Project browsing
            session.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">What is a cookie?</p>
          <p>
            A cookie is a piece of information which a website transfers to the
            cookie file of the browser on your device. The browser saves the
            information and sends it back to the website server whenever the
            browser returns to the website. A cookie will typically contain the
            name of the domain (internet location) from which the cookie has
            come, the "lifetime" of the cookie (i.e. when does it expire), and a
            value, usually a randomly generated unique number. Cookies may be
            deposited through your visits to particular websites or may be
            deposited directly by the Focus Project software. We use the
            following four types of cookies:
          </p>
          <ul>
            <li>
              <p>
                Session cookies which are temporary cookies that remain in the
                cookie file of your browser until you leave the site. We use
                session cookies to:
              </p>
            </li>
            <ul>
              <li>
                <p>Enable you to securely login and connect to our services</p>
              </li>
            </ul>
          </ul>
          <ul>
            <li>
              <p>
                Persistent cookies which remain in the cookie file of your
                browser for much longer (though how long will depend on the
                lifetime of the specific cookie). We use persistent cookies to:
              </p>
            </li>
            <ul>
              <li>
                <p>
                  Enable you to securely login and connect to our services -
                  using the "Remember me" option
                </p>
              </li>
            </ul>
          </ul>
          <ul>
            <li>
              <p>
                First &amp; Third party Advertising cookies which are used to
                help us and other sites serve adverts to you on this and other
                sites that are likely to be of interest to you or relevant to a
                specific task requested of you in that Focus Project browsing
                session
              </p>
            </li>
            <li>
              <p>
                First &amp; Third party Analytics cookies which are used to
                analyse your usage of browsing the internet
              </p>
            </li>
          </ul>
          <p>
            You can opt out of third party advertising cookies by visiting:
            http://www.youronlinechoices.com/uk/opt-out-help
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            How do I know my personal information is safe?
          </p>
          <p>
            The information we collect from you will be analysed in aggregate
            and will not be attributed to you individually. Personally
            identifiable information won't be collected via the Software. When
            you sign up to the project, your Focus Project username will be
            matched with your panellist ID so that the Panel Company through
            which you were recruited can identify you for the purposes of
            administering your participation rewards and for providing you with
            support in relation to the research. Your contact details will only
            be shared with Lumen by the Panel Company if necessary for the
            purpose of administering and providing you with support (including
            technical support) in relation to the Research. Lumen will also
            share with the Panel Company information pertaining to your activity
            in the Research in order that they can administer Rewards in
            relation to the Research and to provide you with support, as
            necessary. No other personally identifiable information that you
            give to us via the Research will be shared with third parties.
          </p>
          <p>
            The data collected by Lumen is encrypted using Secure Sockets Layer
            (SSL) technology. This is a commonly used high standard of
            encryption technology.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            How we use and disclose information we collect?
          </p>
          <p>
            We may disclose anonymised and non-personally identifiable
            information we collect from and about you to our third-party service
            providers, to enable them to provide services to us. We may also
            disclose any information to a third party in the event of any
            reorganization, merger, sale, joint venture, assignment, transfer or
            other disposition of all or any portion of our business, assets, or
            stock (including in connection with any bankruptcy or similar
            proceedings). We may also use or disclose the information we collect
            from and about you as we believe to be necessary or appropriate: (a)
            under applicable law, including laws outside your country of
            residence; (b) to comply with legal process; (c) to respond to
            requests from public and government authorities, including public
            and government authorities outside your country of residence; (d) to
            enforce our end user license agreement; (e) to protect our
            operations or those of any of our affiliates; (f) to protect our
            rights, privacy, safety, or property, and/or that of our affiliates,
            you, or others; and (g) to allow us to pursue available remedies or
            limit the damages that we may sustain.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">Security</p>
          <p>
            We take reasonable steps to protect Your Information using physical,
            electronic, or procedural security measures appropriate to the
            sensitivity of the information in our custody or control, which
            include safeguards to protect against loss or theft, as well as
            unauthorized access, disclosure, copying, use or modification. Only
            our authorized employees and agents who require access to your
            personal information in order to fulfil their job requirements will
            have access to your personal information.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">Retention period</p>
          <p>
            Any of your personal information that we collect will be retained
            for the period necessary to fulfil the purposes outlined.
          </p>
        </section>
        <section className="mb-10">
          <p className="font-semibold mb-10">
            Will this Privacy Policy change over time?
          </p>
          <p>
            We may change this Privacy Policy from time to time. The "Last
            Updated" legend at the top of this page indicates when this Privacy
            Policy was last revised. We will notify you of any changes to the
            Privacy Policy before they become effective and will post the
            revised Privacy Policy on the Focus Project website
            www.focusproject.co.uk.
          </p>
          <p>
            Your acceptance and continued use of Software following these
            changes constitutes an acknowledgement by you of such revisions and
            an agreement by you to abide by and be bound by the revised Privacy
            Policy. Any material change to this Privacy Policy shall not apply
            retroactively to any claim or dispute between you and us in
            connection with this Privacy Policy where such claim arose prior to
            the date on which the Privacy Policy was revised to include such
            material change.
          </p>
        </section>
        <section>
          <p className="font-semibold mb-10">Contacting us</p>
          <p>
            If you have any questions regarding the Privacy Policy, please
            contact Lumen Research at dataprotection@lumen-research.com
          </p>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
