import {
  TrackerController,
  GroundTruthTrackerController,
  ConfigManager,
  Trained,
  ErrorCorrection,
  DesktopConfigV2,
  MobileConfigV2,
  EventManager,
} from "@lumen-developer/rni-webcam-js";
import { BrokerRollbar } from "../utils/rollbar";
import { waitForElm } from "../utils/esm";
import {
  BrokerGaze,
  WebcamBroker,
  DynamicConfig,
  CustomEventListenersDef,
  OnValidationCompleteResult,
} from "./types";

// iOS fix, will be removed
const isIOS =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export default class LRBroker implements WebcamBroker {
  trackersController: TrackerController | GroundTruthTrackerController;
  eventManager: EventManager;
  nextFrameHandler: { remove: Function };
  landmarkDetectorInitializedHandler: { remove: Function };
  landmarksEventHandler: { remove: Function };
  calibrationEventHandler: { remove: Function };
  validationEventHandler: { remove: Function };
  gazeEventHandler: { remove: Function };
  errorEventHandler: { remove: Function };
  timeoutHandler: NodeJS.Timeout;
  frameRateArr: number[];
  errPassthrough: Function;
  state: { initialised: boolean };

  constructor() {
    this.state = {
      initialised: false,
    };
  }

  startTimer = (
    eventRemoval: Function,
    cb: () => void,
    timerLength: number = 60000
  ) => {
    this.timeoutHandler = setTimeout(() => {
      eventRemoval();
      cb();
    }, timerLength);
  };

  /**
   * initialises broker
   * @param {array} customEventListener - Array of objects containing event and callback
   */
  init = (
    timeout: number,
    trackerDiv: HTMLDivElement = null,
    dynConfig: DynamicConfig = null,
    customEventListeners: CustomEventListenersDef[] = null
  ) => {
    return new Promise(async (resolve: (_: null) => void, reject) => {
      const iOSFix = () => {
        const modVidElm = () => {
          const record: HTMLVideoElement =
            document.querySelector("#lr_record_video");
          record.style.position = "fixed";
          record.style.pointerEvents = "none";
          record.style.width = "1px";
          record.style.height = "1px";
          record.style.top = "0";
          record.style.left = "0";
          record.style.opacity = "0.1";
        };

        if (isIOS && !trackerDiv) {
          if (!!document.querySelector("#lr_record_video")) {
            modVidElm();
          } else {
            waitForElm("#lr_record_video").then(() => {
              modVidElm();
            });
          }
        }
      };

      if (this.state.initialised) {
        return;
      }

      /* This sets the timeout for the function and has to be removed when the
       * function is complete
       */
      this.startTimer(
        () => {
          this.nextFrameHandler.remove();
          this.landmarkDetectorInitializedHandler.remove();
        },
        () => {
          throw "init timed out";
        },
        timeout
      );

      let config = null;
      // configv2 is george model
      if (
        !!dynConfig &&
        typeof dynConfig === "object" &&
        !!dynConfig.configV2
      ) {
        if (dynConfig.isMobile) {
          config = new MobileConfigV2();
          config.frameReader.maxWidth = 640;
          config.frameReader.maxHeight = 640;
        } else {
          config = new DesktopConfigV2();
        }
      } else {
        if (dynConfig.testEyetracking) {
          config = new ConfigManager();
          config.frameReader.reader = "VIDEO_FILE";
          config.frameReader.videoFile = dynConfig.videoSrc;
        } else if (dynConfig.silhouette) {
          config = new ConfigManager();
          config.videoView.silhouette = true;
        } else {
          config = new ConfigManager();
        }
      }
      config.frameReader.framerate.ideal = 30;
      config.frameReader.framerate.max = 30;
      config.landmarkDetector.worker.useWebWorkers = false;
      config.gazeValidator.calibrator.view.margin.y = 0.1;
      config.gazeValidator.calibrator.view.margin.x = 0.1;

      // debug
      // config.frameReader.maxFrameDuration = 9999999999999999;

      if (
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor) &&
        !navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)
      ) {
        config.gazeDetector.calibrator.view.realtimeAnimation = false;
        config.gazeValidator.calibrator.view.realtimeAnimation = false;
      }

      // variable config applied here
      // no typescript means no interfaces... means lots of ifs
      if (!!dynConfig && typeof dynConfig === "object") {
        if (dynConfig.useWebWorkers) {
          config.landmarkDetector.worker.useWebWorkers =
            !!dynConfig.useWebWorkers;
        }
      }

      if (
        !!dynConfig &&
        typeof dynConfig === "object" &&
        dynConfig.useGroundTruth
      ) {
        this.trackersController = new GroundTruthTrackerController(config);
      } else {
        this.trackersController = new TrackerController(config);
      }

      this.eventManager = this.trackersController.eventManager();

      if (customEventListeners) {
        customEventListeners.forEach((elObj) => {
          if (!!elObj.eventName && !!elObj.cb) {
            this.eventManager.subscribe(elObj.eventName, (...args: any) => {
              elObj.cb(...args);
            });
          }
        });
      }

      if (trackerDiv) {
        this.trackersController.setVideoDiv(trackerDiv);
      }

      this.errorEventHandler = this.eventManager.subscribe(
        "onError",
        (e: any) => {
          // TODO: handle specific errors here
          BrokerRollbar.error(e.name, e);
          if (this.errPassthrough) {
            this.errPassthrough(e);
          }
        }
      );
      this.state = {
        initialised: true,
      };
      iOSFix();

      // return callback
      this.landmarkDetectorInitializedHandler = this.eventManager.subscribe(
        "onLandmarkDetectorInitialized",
        () => {
          clearTimeout(this.timeoutHandler);
          this.landmarkDetectorInitializedHandler.remove();
          resolve(null);
        }
      );
      // clearTimeout(this.timeoutHandler);
      // cb(true);

      this.nextFrameHandler = this.eventManager.subscribe("onNextFrame", () => {
        this.nextFrameHandler.remove();
        this.trackersController.initLandmarkDetector().catch((err: any) => {
          this.landmarkDetectorInitializedHandler.remove();
          reject(err);
        });
      });

      await Promise.all([
        this.trackersController.initFrameReader(true),
        this.trackersController.initGazeDetector(true),
      ]).catch((err) => {
        this.nextFrameHandler.remove();
        this.landmarkDetectorInitializedHandler.remove();
        reject(err);
      });
    });
  };

  releaseVideoDiv = () => {
    this.trackersController.releaseVideoDiv();
  };

  setVideoDiv = (trackerDiv: HTMLDivElement) => {
    this.trackersController.setVideoDiv(trackerDiv);
  };

  getEyePos = (cb: (res: any) => void) => {
    this.landmarksEventHandler = this.eventManager.subscribe(
      "onNextLandmarks",
      (res: { timestamp: number; frame: any; landmarks: any }) => {
        cb(res.landmarks?.eyes());
      }
    );
  };

  stopEyePos = () => {
    this.landmarksEventHandler.remove();
    this.landmarksEventHandler = null;
  };

  startCalculateFrameRate = () => {
    this.frameRateArr = [];
    this.nextFrameHandler = this.eventManager.subscribe("onNextFrame", () => {
      if (this.frameRateArr.length > 100) {
        this.frameRateArr.shift();
        this.frameRateArr.push(Date.now());
      } else {
        this.frameRateArr.push(Date.now());
      }
    });
  };

  endCalculateFrameRate = () => {
    this.nextFrameHandler.remove();
    // framerate calculator
    const framerate = this.frameRateArr.reduce(function (
      total,
      current,
      index,
      array
    ) {
      if (index < array.length - 1) {
        return total + (array[index + 1] - current);
      } else {
        return total / (array.length - 1);
      }
    },
    0);
    return framerate;
  };

  calibrate = (calib: HTMLDivElement, timeout: number) => {
    return new Promise(async (resolve: (_: null) => void, reject) => {
      if (isIOS) {
        // Refresh camera to fix iOS bug
        // https://lumen-research.atlassian.net/browse/PI-3349
        await this.trackersController.frameReader().stop();
        await this.trackersController.frameReader().start();
      }
      calib.style.display = "unset";
      this.trackersController.releaseVideoDiv();
      this.trackersController.setCalibrationDiv(calib);
      if (this.state.initialised) {
        this.trackersController.startGazeCalibrator();
        this.startTimer(
          () => {
            this.calibrationEventHandler.remove();
          },
          () => {
            reject("calibration timed out");
          },
          timeout
        );

        this.calibrationEventHandler = this.eventManager.subscribe(
          "onCalibrationComplete",
          () => {
            clearTimeout(this.timeoutHandler);
            calib.style.display = "none";
            this.calibrationEventHandler.remove();
            resolve(null);
          }
        );
      }
    });
  };

  validate = (valid: HTMLDivElement, timeout: number) => {
    return new Promise(
      async (resolve: (val: OnValidationCompleteResult) => void, reject) => {
        if (isIOS) {
          // Refresh camera to fix iOS bug
          // https://lumen-research.atlassian.net/browse/PI-3349
          await this.trackersController.frameReader().stop();
          await this.trackersController.frameReader().start();
        }
        valid.style.display = "unset";
        this.trackersController.setValidationDiv(valid);
        if (this.state.initialised) {
          this.trackersController.startGazeValidator();
          this.startTimer(
            () => {
              this.validationEventHandler.remove();
            },
            () => {
              reject("validation timed out");
            },
            timeout
          );
          this.validationEventHandler = this.eventManager.subscribe(
            "onValidationComplete",
            (validationDetails: OnValidationCompleteResult) => {
              clearTimeout(this.timeoutHandler);
              valid.style.display = "none";
              this.validationEventHandler.remove();
              this.trackersController.releaseValidationDiv();
              resolve(validationDetails);
            }
          );
        }
      }
    );
  };

  trackingStart = async (cb: (gaze: BrokerGaze) => void) => {
    if (isIOS) {
      // Refresh camera to fix iOS bug
      // https://lumen-research.atlassian.net/browse/PI-3349
      await this.trackersController.frameReader().stop();
      await this.trackersController.frameReader().start();
    }

    const errorCorrected = !!this.trackersController
      .gazeValidator()
      .errorCorrection();
    const gazeEvent = errorCorrected ? "onNextModifiedGaze" : "onNextRawGaze";

    if (!!this.gazeEventHandler) {
      this.gazeEventHandler.remove();
    }

    this.gazeEventHandler = this.eventManager.subscribe(
      gazeEvent,
      (res: any) => {
        const gaze: BrokerGaze = {
          success: true,
          x: errorCorrected ? res.modifiedGaze._x : res.averagedGaze._x,
          y: errorCorrected ? res.modifiedGaze._y : res.averagedGaze._y,
          original_x: res.averagedGaze._x,
          original_y: res.averagedGaze._y,
          raw_x: res.rawGaze._x,
          raw_y: res.rawGaze._y,
          blinks: false,
          index: errorCorrected
            ? res.modifiedGaze._index
            : res.averagedGaze._index,
          time_from_last: errorCorrected
            ? res.modifiedGaze._duration
            : res.averagedGaze._duration,
          frame_rate: 0,
        };
        cb(gaze);
      }
    );
  };

  trackingStop = () => {
    if (!!this.gazeEventHandler) {
      this.gazeEventHandler.remove();
    }
    this.gazeEventHandler = null;
  };

  turnOffCamera = async () => {
    return this.trackersController.frameReader().stop();
  };

  turnOnCamera = async () => {
    return this.trackersController.frameReader().start();
  };

  // should handle error
  saveModel = (key: string, key2: string) => {
    try {
      if (this.trackersController.detectorCalibrator()) {
        const model = this.trackersController.gazeDetector().model();
        if (model) {
          model.toLocalStorage(key);
          if (!!localStorage && !!sessionStorage) {
            sessionStorage.setItem(key, localStorage.getItem(key));
          }
        }
      }
      if (this.trackersController.gazeValidator()) {
        const errorCorrection = this.trackersController
          .gazeValidator()
          .errorCorrection();
        if (errorCorrection) {
          errorCorrection.toLocalStorage(key2);
          if (!!localStorage && !!sessionStorage) {
            sessionStorage.setItem(key2, localStorage.getItem(key2));
          }
        }
      }
    } catch (e) {
      BrokerRollbar.info("Unable to save model!", e);
    }
  };

  // should handle error
  loadModel = async (key: string, key2: string) => {
    try {
      const model = Trained.fromLocalStorage(key);
      if (this.trackersController.gazeDetector()) {
        this.trackersController.gazeDetector().setModel(model);
      }
      const errorCorrection = ErrorCorrection.fromLocalStorage(key2);
      if (this.trackersController.gazeValidator()) {
        this.trackersController
          .gazeValidator()
          .setErrorCorrection(errorCorrection);
      }
      return;
    } catch (e) {
      BrokerRollbar.info("Unable to load model!", e);
      throw e;
    }
  };

  registerErrPassthrough = (func: Function) => {
    this.errPassthrough = func;
  };
}
