import React, { useRef, useState, useEffect } from "react";
import { submitWildValidationRequest } from "../../../api";
import { ValidationResult } from "../../../types/rni";
import { ErrorDetail, SessionState, SessionValidationProps } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";
import { VALIDATION_SCORE_CEIL } from "../../../utils/constants";

export const SessionValidation = ({
  broker,
  validationSessionDetails,
  validationRef,
  trackerRef,
  setSessionState,
  errorHandle,
  setValidationStatus
}: SessionValidationProps) => {
  const validate = async () => {
    // TODO: SCORE VALIDATION
    try {
      let div = validationRef.current;
      let trackerDiv = trackerRef.current;
      if (!div || !trackerDiv)
        throw ErrorFormatter.getError(ErrorClass.VALIDATION_DIV);
      broker.trackersController._videoView.releaseDivElement();
      let validation: ValidationResult = await broker.validate(div, 50000);
      if (setValidationStatus) {
        setValidationStatus(validation.validatedErrorCorrection._accuracy)
      }
      if (validation.validatedErrorCorrection._accuracy > VALIDATION_SCORE_CEIL || validation.validatedErrorCorrection._accuracy > VALIDATION_SCORE_CEIL) throw ErrorClass.VALIDATION_FAIL
      await submitWildValidationRequest(
        validation,
        validationSessionDetails.panelist,
        validationSessionDetails.panelistId,
        validationSessionDetails.tmpSessionId,
        validationSessionDetails.calibrationTime
      );
      broker.trackersController.setVideoDiv(trackerDiv);
      broker.trackersController._videoView.render();
      cont();
    } catch (e) {
      let div = validationRef.current;
      if (div) {
        div.style.display = "none";
      }
      broker.turnOffCamera();
      if (e === ErrorClass.VALIDATION_FAIL) {
        let detail: ErrorDetail = { 
          message: ErrorFormatter.formatError(ErrorFormatter.getError(e)),
          action: () => {
            broker.trackersController._detectorCalibrator.reset();
            broker.trackersController._gazeValidator.reset();
            broker.turnOnCamera();
            setSessionState(SessionState.CALIBRATION)
          },
          state: SessionState.ERROR
        }
        errorHandle(detail);
      } else {
        errorHandle(ErrorFormatter.formatError(e));
      }
    }
  };
  const cont = () => {
    setSessionState(SessionState.EXTERNAL);
  };
  return (
    <>
      <p className="font-semibold mb-10">Step 4: Validation</p>
      <p className="mb-10">On the next page follow the green dot with your eyes.</p>
      <p className="mb-10">Try to keep your head still.</p>
      <button
        onClick={validate}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Continue
      </button>
    </>
  );
};
