import React from "react";
import { ErrorDetail, SessionSectionProps } from "../session";

interface SessionErrorProps extends SessionSectionProps {
    errorDetail: ErrorDetail;
  }
export const SessionError = ({
  broker,
  dispatch,
  setSessionState,
  errorDetail
}: SessionErrorProps) => {
  return (
    <>
      <p className="font-semibold mb-10">Error!</p>
      <p className="mb-10 text-red-500">{errorDetail.message}</p>
      <p className="mb-10">Please use the button below to try again.</p>
      <button
        onClick={
            errorDetail.action
        }
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Continue
      </button>
    </>
  );
};
