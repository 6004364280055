import React, { useEffect, useState, Fragment } from "react";
import { useMatch } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../hooks/store";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";
import { logOut } from "../../store/reducers/auth";

export interface INavbarItem {
  name: string;
  href: string;
  current: boolean;
}

export const Navbar: React.FC = () => {
  const panelist = useAppSelector((state) => state.auth.panelist);
  const dispatch = useAppDispatch();
  const navigation: INavbarItem[] = [
    {
      name: "Home",
      href: "/",
      current: !!useMatch("/"),
    },
    {
      name: "Tasks",
      // href: "/tasks",
      href: "#",
      current: !!useMatch("/tasks"),
    },
    {
      name: "Rewards",
      // href: "/rewards",
      href: "#",
      current: !!useMatch("/rewards"),
    },
  ];

  return (
    <nav className="p-2 sm:px-6 lg:px-8 relative flex items-center justify-between h-16 bg-secondary shadow">
      <div className="flex-1 flex items-center justify-center h-full lg:items-stretch lg:justify-start">
        <div className={`-my-2 px-4 text-primary flex items-center relative`}>
          <span
            className={`text-primary px-3 h-16 flex items-center text-md font-medium transition ease-in-out duration-300 border-b-2 border-transparent`}
          >
            The Focus Project
          </span>
        </div>
        <div className="hidden lg:flex">
          <div className="flex items-center">
            {navigation.map(({ name, href, current }, index) => (
              <a
                key={index}
                href={href}
                className={`text-primary px-3 h-16 flex items-center text-md font-medium transition ease-in-out duration-300 border-b-2 ${
                  current
                    ? "border-primary bg-secondary-darken"
                    : "border-transparent"
                }`}
                aria-current={current ? "page" : undefined}
              >
                {name}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
        <div className="hidden lg:flex">
          <div className="flex items-center">
            <a
              href={"#"}
              className={`text-primary px-3 h-16 flex items-center text-md font-medium transition ease-in-out duration-300 border-b-2 ${
                !!useMatch("/account")
                  ? "border-primary bg-secondary-darken"
                  : "border-transparent"
              }`}
              aria-current={!!useMatch("/account") ? "page" : undefined}
            >
              {panelist}
            </a>
            <div className="flex items-center">
              <Menu as="div" className="relative">
                <MenuButton>
                  {(props) => (
                    <div
                      className={`px-3 h-16 flex gap-4 items-center border-b-2 ${
                        props.open
                          ? "border-primary bg-secondary-active"
                          : "border-transparent"
                      }`}
                    >
                      <span className="sr-only">Open user menu</span>
                      <Cog8ToothIcon
                        className="h-8 p-1 flex text-primary focus:outline-none"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="origin-top-right absolute right-0 w-64 z-50 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="block px-4 py-2 bg-secondary rounded-t-md text-white xl:hidden"></div>
                    <MenuItem>
                      {({ active, close }) => (
                        <div className={"bg-gray-100"}>
                          <a href="/privacy-policy">
                            <div
                              className="block px-4 py-2 text-gray-700 cursor-pointer"
                            >
                              Privacy Policy
                            </div>
                          </a>
                        </div>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active, close }) => (
                        <div className={"bg-gray-100"}>
                          <a href="/terms-and-conditions">
                            <div
                              className="block px-4 py-2 text-gray-700 cursor-pointer"
                            >
                              Terms and Conditions
                            </div>
                          </a>
                        </div>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active, close }) => (
                        <div className={"bg-gray-100"}>
                          <a>
                            <div
                              className="block px-4 py-2 text-gray-700 cursor-pointer"
                              onClick={() => {
                                close();
                                console.log(dispatch(logOut()));
                              }}
                            >
                              Log Out
                            </div>
                          </a>
                        </div>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
