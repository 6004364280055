import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from "./root";
import Home from "./pages/home";
import Install from "./pages/install";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root></Root>,
    errorElement: <h1>Error</h1>, // TODO: Error Element,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/install",
        element: <Install />,
      },
      {
        path: "/privacy-policy",
        element: <Privacy />,
      },
      {
        path: "/terms-and-conditions",
        element: <Terms />,
      },
    ]
  },
]);

export function App() {
  return <RouterProvider router={router} />
}