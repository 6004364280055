"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Module = (function () {
    var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
    return (function (Module) {
        if (Module === void 0) { Module = {}; }
        var Module = typeof Module != "undefined" ? Module : {};
        var readyPromiseResolve, readyPromiseReject;
        Module["ready"] = new Promise(function (resolve, reject) { readyPromiseResolve = resolve; readyPromiseReject = reject; });
        var moduleOverrides = Object.assign({}, Module);
        var arguments_ = [];
        var thisProgram = "./this.program";
        var quit_ = function (status, toThrow) { throw toThrow; };
        var ENVIRONMENT_IS_WEB = typeof window == "object";
        var ENVIRONMENT_IS_WORKER = typeof importScripts == "function";
        var ENVIRONMENT_IS_NODE = typeof process == "object" && typeof process.versions == "object" && typeof process.versions.node == "string";
        var scriptDirectory = "";
        function locateFile(path) { if (Module["locateFile"]) {
            return Module["locateFile"](path, scriptDirectory);
        } return scriptDirectory + path; }
        var read_, readAsync, readBinary, setWindowTitle;
        if (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER) {
            if (ENVIRONMENT_IS_WORKER) {
                scriptDirectory = self.location.href;
            }
            else if (typeof document != "undefined" && document.currentScript) {
                scriptDirectory = document.currentScript.src;
            }
            if (_scriptDir) {
                scriptDirectory = _scriptDir;
            }
            if (scriptDirectory.indexOf("blob:") !== 0) {
                scriptDirectory = scriptDirectory.substr(0, scriptDirectory.replace(/[?#].*/, "").lastIndexOf("/") + 1);
            }
            else {
                scriptDirectory = "";
            }
            {
                read_ = function (url) { var xhr = new XMLHttpRequest; xhr.open("GET", url, false); xhr.send(null); return xhr.responseText; };
                if (ENVIRONMENT_IS_WORKER) {
                    readBinary = function (url) { var xhr = new XMLHttpRequest; xhr.open("GET", url, false); xhr.responseType = "arraybuffer"; xhr.send(null); return new Uint8Array(xhr.response); };
                }
                readAsync = function (url, onload, onerror) { var xhr = new XMLHttpRequest; xhr.open("GET", url, true); xhr.responseType = "arraybuffer"; xhr.onload = function () { if (xhr.status == 200 || xhr.status == 0 && xhr.response) {
                    onload(xhr.response);
                    return;
                } onerror(); }; xhr.onerror = onerror; xhr.send(null); };
            }
            setWindowTitle = function (title) { return document.title = title; };
        }
        else { }
        var out = Module["print"] || console.log.bind(console);
        var err = Module["printErr"] || console.warn.bind(console);
        Object.assign(Module, moduleOverrides);
        moduleOverrides = null;
        if (Module["arguments"])
            arguments_ = Module["arguments"];
        if (Module["thisProgram"])
            thisProgram = Module["thisProgram"];
        if (Module["quit"])
            quit_ = Module["quit"];
        var wasmBinary;
        if (Module["wasmBinary"])
            wasmBinary = Module["wasmBinary"];
        var noExitRuntime = Module["noExitRuntime"] || true;
        if (typeof WebAssembly != "object") {
            abort("no native wasm support detected");
        }
        var wasmMemory;
        var ABORT = false;
        var EXITSTATUS;
        function assert(condition, text) { if (!condition) {
            abort(text);
        } }
        var UTF8Decoder = typeof TextDecoder != "undefined" ? new TextDecoder("utf8") : undefined;
        function UTF8ArrayToString(heapOrArray, idx, maxBytesToRead) { var endIdx = idx + maxBytesToRead; var endPtr = idx; while (heapOrArray[endPtr] && !(endPtr >= endIdx))
            ++endPtr; if (endPtr - idx > 16 && heapOrArray.buffer && UTF8Decoder) {
            return UTF8Decoder.decode(heapOrArray.subarray(idx, endPtr));
        } var str = ""; while (idx < endPtr) {
            var u0 = heapOrArray[idx++];
            if (!(u0 & 128)) {
                str += String.fromCharCode(u0);
                continue;
            }
            var u1 = heapOrArray[idx++] & 63;
            if ((u0 & 224) == 192) {
                str += String.fromCharCode((u0 & 31) << 6 | u1);
                continue;
            }
            var u2 = heapOrArray[idx++] & 63;
            if ((u0 & 240) == 224) {
                u0 = (u0 & 15) << 12 | u1 << 6 | u2;
            }
            else {
                u0 = (u0 & 7) << 18 | u1 << 12 | u2 << 6 | heapOrArray[idx++] & 63;
            }
            if (u0 < 65536) {
                str += String.fromCharCode(u0);
            }
            else {
                var ch = u0 - 65536;
                str += String.fromCharCode(55296 | ch >> 10, 56320 | ch & 1023);
            }
        } return str; }
        function UTF8ToString(ptr, maxBytesToRead) { return ptr ? UTF8ArrayToString(HEAPU8, ptr, maxBytesToRead) : ""; }
        function stringToUTF8Array(str, heap, outIdx, maxBytesToWrite) { if (!(maxBytesToWrite > 0))
            return 0; var startIdx = outIdx; var endIdx = outIdx + maxBytesToWrite - 1; for (var i = 0; i < str.length; ++i) {
            var u = str.charCodeAt(i);
            if (u >= 55296 && u <= 57343) {
                var u1 = str.charCodeAt(++i);
                u = 65536 + ((u & 1023) << 10) | u1 & 1023;
            }
            if (u <= 127) {
                if (outIdx >= endIdx)
                    break;
                heap[outIdx++] = u;
            }
            else if (u <= 2047) {
                if (outIdx + 1 >= endIdx)
                    break;
                heap[outIdx++] = 192 | u >> 6;
                heap[outIdx++] = 128 | u & 63;
            }
            else if (u <= 65535) {
                if (outIdx + 2 >= endIdx)
                    break;
                heap[outIdx++] = 224 | u >> 12;
                heap[outIdx++] = 128 | u >> 6 & 63;
                heap[outIdx++] = 128 | u & 63;
            }
            else {
                if (outIdx + 3 >= endIdx)
                    break;
                heap[outIdx++] = 240 | u >> 18;
                heap[outIdx++] = 128 | u >> 12 & 63;
                heap[outIdx++] = 128 | u >> 6 & 63;
                heap[outIdx++] = 128 | u & 63;
            }
        } heap[outIdx] = 0; return outIdx - startIdx; }
        function lengthBytesUTF8(str) { var len = 0; for (var i = 0; i < str.length; ++i) {
            var c = str.charCodeAt(i);
            if (c <= 127) {
                len++;
            }
            else if (c <= 2047) {
                len += 2;
            }
            else if (c >= 55296 && c <= 57343) {
                len += 4;
                ++i;
            }
            else {
                len += 3;
            }
        } return len; }
        var HEAP8, HEAPU8, HEAP16, HEAPU16, HEAP32, HEAPU32, HEAPF32, HEAPF64;
        function updateMemoryViews() { var b = wasmMemory.buffer; Module["HEAP8"] = HEAP8 = new Int8Array(b); Module["HEAP16"] = HEAP16 = new Int16Array(b); Module["HEAP32"] = HEAP32 = new Int32Array(b); Module["HEAPU8"] = HEAPU8 = new Uint8Array(b); Module["HEAPU16"] = HEAPU16 = new Uint16Array(b); Module["HEAPU32"] = HEAPU32 = new Uint32Array(b); Module["HEAPF32"] = HEAPF32 = new Float32Array(b); Module["HEAPF64"] = HEAPF64 = new Float64Array(b); }
        var wasmTable;
        var __ATPRERUN__ = [];
        var __ATINIT__ = [];
        var __ATPOSTRUN__ = [];
        var runtimeInitialized = false;
        function keepRuntimeAlive() { return noExitRuntime; }
        function preRun() { if (Module["preRun"]) {
            if (typeof Module["preRun"] == "function")
                Module["preRun"] = [Module["preRun"]];
            while (Module["preRun"].length) {
                addOnPreRun(Module["preRun"].shift());
            }
        } callRuntimeCallbacks(__ATPRERUN__); }
        function initRuntime() { runtimeInitialized = true; callRuntimeCallbacks(__ATINIT__); }
        function postRun() { if (Module["postRun"]) {
            if (typeof Module["postRun"] == "function")
                Module["postRun"] = [Module["postRun"]];
            while (Module["postRun"].length) {
                addOnPostRun(Module["postRun"].shift());
            }
        } callRuntimeCallbacks(__ATPOSTRUN__); }
        function addOnPreRun(cb) { __ATPRERUN__.unshift(cb); }
        function addOnInit(cb) { __ATINIT__.unshift(cb); }
        function addOnPostRun(cb) { __ATPOSTRUN__.unshift(cb); }
        var runDependencies = 0;
        var runDependencyWatcher = null;
        var dependenciesFulfilled = null;
        function addRunDependency(id) { runDependencies++; if (Module["monitorRunDependencies"]) {
            Module["monitorRunDependencies"](runDependencies);
        } }
        function removeRunDependency(id) { runDependencies--; if (Module["monitorRunDependencies"]) {
            Module["monitorRunDependencies"](runDependencies);
        } if (runDependencies == 0) {
            if (runDependencyWatcher !== null) {
                clearInterval(runDependencyWatcher);
                runDependencyWatcher = null;
            }
            if (dependenciesFulfilled) {
                var callback = dependenciesFulfilled;
                dependenciesFulfilled = null;
                callback();
            }
        } }
        function abort(what) { if (Module["onAbort"]) {
            Module["onAbort"](what);
        } what = "Aborted(" + what + ")"; err(what); ABORT = true; EXITSTATUS = 1; what += ". Build with -sASSERTIONS for more info."; var e = new WebAssembly.RuntimeError(what); readyPromiseReject(e); throw e; }
        var dataURIPrefix = "data:application/octet-stream;base64,";
        function isDataURI(filename) { return filename.startsWith(dataURIPrefix); }
        function isFileURI(filename) { return filename.startsWith("file://"); }
        var wasmBinaryFile;
        wasmBinaryFile = "lumen_tracker_main.wasm";
        if (!isDataURI(wasmBinaryFile)) {
            wasmBinaryFile = locateFile(wasmBinaryFile);
        }
        function getBinary(file) { try {
            if (file == wasmBinaryFile && wasmBinary) {
                return new Uint8Array(wasmBinary);
            }
            if (readBinary) {
                return readBinary(file);
            }
            throw "both async and sync fetching of the wasm failed";
        }
        catch (err) {
            abort(err);
        } }
        function getBinaryPromise(binaryFile) { if (!wasmBinary && (ENVIRONMENT_IS_WEB || ENVIRONMENT_IS_WORKER)) {
            if (typeof fetch == "function" && !isFileURI(binaryFile)) {
                return fetch(binaryFile, { credentials: "same-origin" }).then(function (response) { if (!response["ok"]) {
                    throw "failed to load wasm binary file at '" + binaryFile + "'";
                } return response["arrayBuffer"](); }).catch(function () { return getBinary(binaryFile); });
            }
            else {
                if (readAsync) {
                    return new Promise(function (resolve, reject) { readAsync(binaryFile, function (response) { resolve(new Uint8Array(response)); }, reject); });
                }
            }
        } return Promise.resolve().then(function () { return getBinary(binaryFile); }); }
        function instantiateArrayBuffer(binaryFile, imports, receiver) { return getBinaryPromise(binaryFile).then(function (binary) { return WebAssembly.instantiate(binary, imports); }).then(function (instance) { return instance; }).then(receiver, function (reason) { err("failed to asynchronously prepare wasm: " + reason); abort(reason); }); }
        function instantiateAsync(binary, binaryFile, imports, callback) { if (!binary && typeof WebAssembly.instantiateStreaming == "function" && !isDataURI(binaryFile) && !isFileURI(binaryFile) && typeof fetch == "function") {
            return fetch(binaryFile, { credentials: "same-origin" }).then(function (response) { var result = WebAssembly.instantiateStreaming(response, imports); return result.then(callback, function (reason) { err("wasm streaming compile failed: " + reason); err("falling back to ArrayBuffer instantiation"); return instantiateArrayBuffer(binaryFile, imports, callback); }); });
        }
        else {
            return instantiateArrayBuffer(binaryFile, imports, callback);
        } }
        function createWasm() { var info = { "env": wasmImports, "wasi_snapshot_preview1": wasmImports }; function receiveInstance(instance, module) { var exports = instance.exports; Module["asm"] = exports; wasmMemory = Module["asm"]["memory"]; updateMemoryViews(); wasmTable = Module["asm"]["__indirect_function_table"]; addOnInit(Module["asm"]["__wasm_call_ctors"]); removeRunDependency("wasm-instantiate"); return exports; } addRunDependency("wasm-instantiate"); function receiveInstantiationResult(result) { receiveInstance(result["instance"]); } if (Module["instantiateWasm"]) {
            try {
                return Module["instantiateWasm"](info, receiveInstance);
            }
            catch (e) {
                err("Module.instantiateWasm callback failed with error: " + e);
                readyPromiseReject(e);
            }
        } instantiateAsync(wasmBinary, wasmBinaryFile, info, receiveInstantiationResult).catch(readyPromiseReject); return {}; }
        var tempDouble;
        var tempI64;
        var ASM_CONSTS = { 2535748: function () { return Module.getRandomValue(); }, 2535784: function () { if (Module.getRandomValue === undefined) {
                try {
                    var window_ = "object" === typeof window ? window : self;
                    var crypto_ = typeof window_.crypto !== "undefined" ? window_.crypto : window_.msCrypto;
                    var randomValuesStandard = function () { var buf = new Uint32Array(1); crypto_.getRandomValues(buf); return buf[0] >>> 0; };
                    randomValuesStandard();
                    Module.getRandomValue = randomValuesStandard;
                }
                catch (e) {
                    try {
                        var crypto = require("crypto");
                        var randomValueNodeJS = function () { var buf = crypto["randomBytes"](4); return (buf[0] << 24 | buf[1] << 16 | buf[2] << 8 | buf[3]) >>> 0; };
                        randomValueNodeJS();
                        Module.getRandomValue = randomValueNodeJS;
                    }
                    catch (e) {
                        throw "No secure random number generator found";
                    }
                }
            } } };
        function ExitStatus(status) { this.name = "ExitStatus"; this.message = "Program terminated with exit(" + status + ")"; this.status = status; }
        function callRuntimeCallbacks(callbacks) { while (callbacks.length > 0) {
            callbacks.shift()(Module);
        } }
        var exceptionCaught = [];
        function exception_addRef(info) { info.add_ref(); }
        var uncaughtExceptionCount = 0;
        function ___cxa_begin_catch(ptr) { var info = new ExceptionInfo(ptr); if (!info.get_caught()) {
            info.set_caught(true);
            uncaughtExceptionCount--;
        } info.set_rethrown(false); exceptionCaught.push(info); exception_addRef(info); return info.get_exception_ptr(); }
        var exceptionLast = 0;
        var wasmTableMirror = [];
        function getWasmTableEntry(funcPtr) { var func = wasmTableMirror[funcPtr]; if (!func) {
            if (funcPtr >= wasmTableMirror.length)
                wasmTableMirror.length = funcPtr + 1;
            wasmTableMirror[funcPtr] = func = wasmTable.get(funcPtr);
        } return func; }
        function exception_decRef(info) { if (info.release_ref() && !info.get_rethrown()) {
            var destructor = info.get_destructor();
            if (destructor) {
                getWasmTableEntry(destructor)(info.excPtr);
            }
            ___cxa_free_exception(info.excPtr);
        } }
        function ___cxa_end_catch() { _setThrew(0); var info = exceptionCaught.pop(); exception_decRef(info); exceptionLast = 0; }
        function ExceptionInfo(excPtr) { this.excPtr = excPtr; this.ptr = excPtr - 24; this.set_type = function (type) { HEAPU32[this.ptr + 4 >> 2] = type; }; this.get_type = function () { return HEAPU32[this.ptr + 4 >> 2]; }; this.set_destructor = function (destructor) { HEAPU32[this.ptr + 8 >> 2] = destructor; }; this.get_destructor = function () { return HEAPU32[this.ptr + 8 >> 2]; }; this.set_refcount = function (refcount) { HEAP32[this.ptr >> 2] = refcount; }; this.set_caught = function (caught) { caught = caught ? 1 : 0; HEAP8[this.ptr + 12 >> 0] = caught; }; this.get_caught = function () { return HEAP8[this.ptr + 12 >> 0] != 0; }; this.set_rethrown = function (rethrown) { rethrown = rethrown ? 1 : 0; HEAP8[this.ptr + 13 >> 0] = rethrown; }; this.get_rethrown = function () { return HEAP8[this.ptr + 13 >> 0] != 0; }; this.init = function (type, destructor) { this.set_adjusted_ptr(0); this.set_type(type); this.set_destructor(destructor); this.set_refcount(0); this.set_caught(false); this.set_rethrown(false); }; this.add_ref = function () { var value = HEAP32[this.ptr >> 2]; HEAP32[this.ptr >> 2] = value + 1; }; this.release_ref = function () { var prev = HEAP32[this.ptr >> 2]; HEAP32[this.ptr >> 2] = prev - 1; return prev === 1; }; this.set_adjusted_ptr = function (adjustedPtr) { HEAPU32[this.ptr + 16 >> 2] = adjustedPtr; }; this.get_adjusted_ptr = function () { return HEAPU32[this.ptr + 16 >> 2]; }; this.get_exception_ptr = function () { var isPointer = ___cxa_is_pointer_type(this.get_type()); if (isPointer) {
            return HEAPU32[this.excPtr >> 2];
        } var adjusted = this.get_adjusted_ptr(); if (adjusted !== 0)
            return adjusted; return this.excPtr; }; }
        function ___resumeException(ptr) { if (!exceptionLast) {
            exceptionLast = ptr;
        } throw ptr; }
        function ___cxa_find_matching_catch() { var thrown = exceptionLast; if (!thrown) {
            setTempRet0(0);
            return 0;
        } var info = new ExceptionInfo(thrown); info.set_adjusted_ptr(thrown); var thrownType = info.get_type(); if (!thrownType) {
            setTempRet0(0);
            return thrown;
        } for (var i = 0; i < arguments.length; i++) {
            var caughtType = arguments[i];
            if (caughtType === 0 || caughtType === thrownType) {
                break;
            }
            var adjusted_ptr_addr = info.ptr + 16;
            if (___cxa_can_catch(caughtType, thrownType, adjusted_ptr_addr)) {
                setTempRet0(caughtType);
                return thrown;
            }
        } setTempRet0(thrownType); return thrown; }
        var ___cxa_find_matching_catch_2 = ___cxa_find_matching_catch;
        var ___cxa_find_matching_catch_3 = ___cxa_find_matching_catch;
        var ___cxa_find_matching_catch_4 = ___cxa_find_matching_catch;
        function ___cxa_rethrow() { var info = exceptionCaught.pop(); if (!info) {
            abort("no exception to throw");
        } var ptr = info.excPtr; if (!info.get_rethrown()) {
            exceptionCaught.push(info);
            info.set_rethrown(true);
            info.set_caught(false);
            uncaughtExceptionCount++;
        } exceptionLast = ptr; throw ptr; }
        function ___cxa_throw(ptr, type, destructor) { var info = new ExceptionInfo(ptr); info.init(type, destructor); exceptionLast = ptr; uncaughtExceptionCount++; throw ptr; }
        var dlopenMissingError = "To use dlopen, you need enable dynamic linking, see https://github.com/emscripten-core/emscripten/wiki/Linking";
        function ___dlsym(handle, symbol) { abort(dlopenMissingError); }
        var SYSCALLS = { varargs: undefined, get: function () { SYSCALLS.varargs += 4; var ret = HEAP32[SYSCALLS.varargs - 4 >> 2]; return ret; }, getStr: function (ptr) { var ret = UTF8ToString(ptr); return ret; } };
        function ___syscall_fcntl64(fd, cmd, varargs) { SYSCALLS.varargs = varargs; return 0; }
        function ___syscall_ioctl(fd, op, varargs) { SYSCALLS.varargs = varargs; return 0; }
        function ___syscall_openat(dirfd, path, flags, varargs) { SYSCALLS.varargs = varargs; }
        var nowIsMonotonic = true;
        function __emscripten_get_now_is_monotonic() { return nowIsMonotonic; }
        function _abort() { abort(""); }
        function _dlopen(handle) { abort(dlopenMissingError); }
        var readEmAsmArgsArray = [];
        function readEmAsmArgs(sigPtr, buf) { readEmAsmArgsArray.length = 0; var ch; buf >>= 2; while (ch = HEAPU8[sigPtr++]) {
            buf += ch != 105 & buf;
            readEmAsmArgsArray.push(ch == 105 ? HEAP32[buf] : HEAPF64[buf++ >> 1]);
            ++buf;
        } return readEmAsmArgsArray; }
        function runEmAsmFunction(code, sigPtr, argbuf) { var args = readEmAsmArgs(sigPtr, argbuf); return ASM_CONSTS[code].apply(null, args); }
        function _emscripten_asm_const_int(code, sigPtr, argbuf) { return runEmAsmFunction(code, sigPtr, argbuf); }
        function _emscripten_set_main_loop_timing(mode, value) { Browser.mainLoop.timingMode = mode; Browser.mainLoop.timingValue = value; if (!Browser.mainLoop.func) {
            return 1;
        } if (!Browser.mainLoop.running) {
            Browser.mainLoop.running = true;
        } if (mode == 0) {
            Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_setTimeout() { var timeUntilNextTick = Math.max(0, Browser.mainLoop.tickStartTime + value - _emscripten_get_now()) | 0; setTimeout(Browser.mainLoop.runner, timeUntilNextTick); };
            Browser.mainLoop.method = "timeout";
        }
        else if (mode == 1) {
            Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_rAF() { Browser.requestAnimationFrame(Browser.mainLoop.runner); };
            Browser.mainLoop.method = "rAF";
        }
        else if (mode == 2) {
            if (typeof setImmediate == "undefined") {
                var setImmediates = [];
                var emscriptenMainLoopMessageId = "setimmediate";
                var Browser_setImmediate_messageHandler = function (event) { if (event.data === emscriptenMainLoopMessageId || event.data.target === emscriptenMainLoopMessageId) {
                    event.stopPropagation();
                    setImmediates.shift()();
                } };
                addEventListener("message", Browser_setImmediate_messageHandler, true);
                setImmediate = function Browser_emulated_setImmediate(func) { setImmediates.push(func); if (ENVIRONMENT_IS_WORKER) {
                    if (Module["setImmediates"] === undefined)
                        Module["setImmediates"] = [];
                    Module["setImmediates"].push(func);
                    postMessage({ target: emscriptenMainLoopMessageId });
                }
                else
                    postMessage(emscriptenMainLoopMessageId, "*"); };
            }
            Browser.mainLoop.scheduler = function Browser_mainLoop_scheduler_setImmediate() { setImmediate(Browser.mainLoop.runner); };
            Browser.mainLoop.method = "immediate";
        } return 0; }
        var _emscripten_get_now;
        _emscripten_get_now = function () { return performance.now(); };
        function setMainLoop(browserIterationFunc, fps, simulateInfiniteLoop, arg, noSetTiming) { assert(!Browser.mainLoop.func, "emscripten_set_main_loop: there can only be one main loop function at once: call emscripten_cancel_main_loop to cancel the previous one before setting a new one with different parameters."); Browser.mainLoop.func = browserIterationFunc; Browser.mainLoop.arg = arg; var thisMainLoopId = Browser.mainLoop.currentlyRunningMainloop; function checkIsRunning() { if (thisMainLoopId < Browser.mainLoop.currentlyRunningMainloop) {
            return false;
        } return true; } Browser.mainLoop.running = false; Browser.mainLoop.runner = function Browser_mainLoop_runner() { if (ABORT)
            return; if (Browser.mainLoop.queue.length > 0) {
            var start = Date.now();
            var blocker = Browser.mainLoop.queue.shift();
            blocker.func(blocker.arg);
            if (Browser.mainLoop.remainingBlockers) {
                var remaining = Browser.mainLoop.remainingBlockers;
                var next = remaining % 1 == 0 ? remaining - 1 : Math.floor(remaining);
                if (blocker.counted) {
                    Browser.mainLoop.remainingBlockers = next;
                }
                else {
                    next = next + .5;
                    Browser.mainLoop.remainingBlockers = (8 * remaining + next) / 9;
                }
            }
            out('main loop blocker "' + blocker.name + '" took ' + (Date.now() - start) + " ms");
            Browser.mainLoop.updateStatus();
            if (!checkIsRunning())
                return;
            setTimeout(Browser.mainLoop.runner, 0);
            return;
        } if (!checkIsRunning())
            return; Browser.mainLoop.currentFrameNumber = Browser.mainLoop.currentFrameNumber + 1 | 0; if (Browser.mainLoop.timingMode == 1 && Browser.mainLoop.timingValue > 1 && Browser.mainLoop.currentFrameNumber % Browser.mainLoop.timingValue != 0) {
            Browser.mainLoop.scheduler();
            return;
        }
        else if (Browser.mainLoop.timingMode == 0) {
            Browser.mainLoop.tickStartTime = _emscripten_get_now();
        } Browser.mainLoop.runIter(browserIterationFunc); if (!checkIsRunning())
            return; if (typeof SDL == "object" && SDL.audio && SDL.audio.queueNewAudioData)
            SDL.audio.queueNewAudioData(); Browser.mainLoop.scheduler(); }; if (!noSetTiming) {
            if (fps && fps > 0)
                _emscripten_set_main_loop_timing(0, 1e3 / fps);
            else
                _emscripten_set_main_loop_timing(1, 1);
            Browser.mainLoop.scheduler();
        } if (simulateInfiniteLoop) {
            throw "unwind";
        } }
        function handleException(e) { if (e instanceof ExitStatus || e == "unwind") {
            return EXITSTATUS;
        } quit_(1, e); }
        function callUserCallback(func) { if (ABORT) {
            return;
        } try {
            func();
        }
        catch (e) {
            handleException(e);
        } }
        function safeSetTimeout(func, timeout) { return setTimeout(function () { callUserCallback(func); }, timeout); }
        function warnOnce(text) { if (!warnOnce.shown)
            warnOnce.shown = {}; if (!warnOnce.shown[text]) {
            warnOnce.shown[text] = 1;
            err(text);
        } }
        var Browser = { mainLoop: { running: false, scheduler: null, method: "", currentlyRunningMainloop: 0, func: null, arg: 0, timingMode: 0, timingValue: 0, currentFrameNumber: 0, queue: [], pause: function () { Browser.mainLoop.scheduler = null; Browser.mainLoop.currentlyRunningMainloop++; }, resume: function () { Browser.mainLoop.currentlyRunningMainloop++; var timingMode = Browser.mainLoop.timingMode; var timingValue = Browser.mainLoop.timingValue; var func = Browser.mainLoop.func; Browser.mainLoop.func = null; setMainLoop(func, 0, false, Browser.mainLoop.arg, true); _emscripten_set_main_loop_timing(timingMode, timingValue); Browser.mainLoop.scheduler(); }, updateStatus: function () { if (Module["setStatus"]) {
                    var message = Module["statusMessage"] || "Please wait...";
                    var remaining = Browser.mainLoop.remainingBlockers;
                    var expected = Browser.mainLoop.expectedBlockers;
                    if (remaining) {
                        if (remaining < expected) {
                            Module["setStatus"](message + " (" + (expected - remaining) + "/" + expected + ")");
                        }
                        else {
                            Module["setStatus"](message);
                        }
                    }
                    else {
                        Module["setStatus"]("");
                    }
                } }, runIter: function (func) { if (ABORT)
                    return; if (Module["preMainLoop"]) {
                    var preRet = Module["preMainLoop"]();
                    if (preRet === false) {
                        return;
                    }
                } callUserCallback(func); if (Module["postMainLoop"])
                    Module["postMainLoop"](); } }, isFullscreen: false, pointerLock: false, moduleContextCreatedCallbacks: [], workers: [], init: function () { if (!Module["preloadPlugins"])
                Module["preloadPlugins"] = []; if (Browser.initted)
                return; Browser.initted = true; try {
                new Blob;
                Browser.hasBlobConstructor = true;
            }
            catch (e) {
                Browser.hasBlobConstructor = false;
                err("warning: no blob constructor, cannot create blobs with mimetypes");
            } Browser.BlobBuilder = typeof MozBlobBuilder != "undefined" ? MozBlobBuilder : typeof WebKitBlobBuilder != "undefined" ? WebKitBlobBuilder : !Browser.hasBlobConstructor ? err("warning: no BlobBuilder") : null; Browser.URLObject = typeof window != "undefined" ? window.URL ? window.URL : window.webkitURL : undefined; if (!Module.noImageDecoding && typeof Browser.URLObject == "undefined") {
                err("warning: Browser does not support creating object URLs. Built-in browser image decoding will not be available.");
                Module.noImageDecoding = true;
            } var imagePlugin = {}; imagePlugin["canHandle"] = function imagePlugin_canHandle(name) { return !Module.noImageDecoding && /\.(jpg|jpeg|png|bmp)$/i.test(name); }; imagePlugin["handle"] = function imagePlugin_handle(byteArray, name, onload, onerror) { var b = null; if (Browser.hasBlobConstructor) {
                try {
                    b = new Blob([byteArray], { type: Browser.getMimetype(name) });
                    if (b.size !== byteArray.length) {
                        b = new Blob([new Uint8Array(byteArray).buffer], { type: Browser.getMimetype(name) });
                    }
                }
                catch (e) {
                    warnOnce("Blob constructor present but fails: " + e + "; falling back to blob builder");
                }
            } if (!b) {
                var bb = new Browser.BlobBuilder;
                bb.append(new Uint8Array(byteArray).buffer);
                b = bb.getBlob();
            } var url = Browser.URLObject.createObjectURL(b); var img = new Image; img.onload = function () { assert(img.complete, "Image " + name + " could not be decoded"); var canvas = document.createElement("canvas"); canvas.width = img.width; canvas.height = img.height; var ctx = canvas.getContext("2d"); ctx.drawImage(img, 0, 0); preloadedImages[name] = canvas; Browser.URLObject.revokeObjectURL(url); if (onload)
                onload(byteArray); }; img.onerror = function (event) { out("Image " + url + " could not be decoded"); if (onerror)
                onerror(); }; img.src = url; }; Module["preloadPlugins"].push(imagePlugin); var audioPlugin = {}; audioPlugin["canHandle"] = function audioPlugin_canHandle(name) { return !Module.noAudioDecoding && name.substr(-4) in { ".ogg": 1, ".wav": 1, ".mp3": 1 }; }; audioPlugin["handle"] = function audioPlugin_handle(byteArray, name, onload, onerror) { var done = false; function finish(audio) { if (done)
                return; done = true; preloadedAudios[name] = audio; if (onload)
                onload(byteArray); } function fail() { if (done)
                return; done = true; preloadedAudios[name] = new Audio; if (onerror)
                onerror(); } if (Browser.hasBlobConstructor) {
                try {
                    var b = new Blob([byteArray], { type: Browser.getMimetype(name) });
                }
                catch (e) {
                    return fail();
                }
                var url = Browser.URLObject.createObjectURL(b);
                var audio = new Audio;
                audio.addEventListener("canplaythrough", function () { return finish(audio); }, false);
                audio.onerror = function audio_onerror(event) { if (done)
                    return; err("warning: browser could not fully decode audio " + name + ", trying slower base64 approach"); function encode64(data) { var BASE = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"; var PAD = "="; var ret = ""; var leftchar = 0; var leftbits = 0; for (var i = 0; i < data.length; i++) {
                    leftchar = leftchar << 8 | data[i];
                    leftbits += 8;
                    while (leftbits >= 6) {
                        var curr = leftchar >> leftbits - 6 & 63;
                        leftbits -= 6;
                        ret += BASE[curr];
                    }
                } if (leftbits == 2) {
                    ret += BASE[(leftchar & 3) << 4];
                    ret += PAD + PAD;
                }
                else if (leftbits == 4) {
                    ret += BASE[(leftchar & 15) << 2];
                    ret += PAD;
                } return ret; } audio.src = "data:audio/x-" + name.substr(-3) + ";base64," + encode64(byteArray); finish(audio); };
                audio.src = url;
                safeSetTimeout(function () { finish(audio); }, 1e4);
            }
            else {
                return fail();
            } }; Module["preloadPlugins"].push(audioPlugin); function pointerLockChange() { Browser.pointerLock = document["pointerLockElement"] === Module["canvas"] || document["mozPointerLockElement"] === Module["canvas"] || document["webkitPointerLockElement"] === Module["canvas"] || document["msPointerLockElement"] === Module["canvas"]; } var canvas = Module["canvas"]; if (canvas) {
                canvas.requestPointerLock = canvas["requestPointerLock"] || canvas["mozRequestPointerLock"] || canvas["webkitRequestPointerLock"] || canvas["msRequestPointerLock"] || (function () { });
                canvas.exitPointerLock = document["exitPointerLock"] || document["mozExitPointerLock"] || document["webkitExitPointerLock"] || document["msExitPointerLock"] || (function () { });
                canvas.exitPointerLock = canvas.exitPointerLock.bind(document);
                document.addEventListener("pointerlockchange", pointerLockChange, false);
                document.addEventListener("mozpointerlockchange", pointerLockChange, false);
                document.addEventListener("webkitpointerlockchange", pointerLockChange, false);
                document.addEventListener("mspointerlockchange", pointerLockChange, false);
                if (Module["elementPointerLock"]) {
                    canvas.addEventListener("click", function (ev) { if (!Browser.pointerLock && Module["canvas"].requestPointerLock) {
                        Module["canvas"].requestPointerLock();
                        ev.preventDefault();
                    } }, false);
                }
            } }, handledByPreloadPlugin: function (byteArray, fullname, finish, onerror) { Browser.init(); var handled = false; Module["preloadPlugins"].forEach(function (plugin) { if (handled)
                return; if (plugin["canHandle"](fullname)) {
                plugin["handle"](byteArray, fullname, finish, onerror);
                handled = true;
            } }); return handled; }, createContext: function (canvas, useWebGL, setInModule, webGLContextAttributes) { if (useWebGL && Module.ctx && canvas == Module.canvas)
                return Module.ctx; var ctx; var contextHandle; if (useWebGL) {
                var contextAttributes = { antialias: false, alpha: false, majorVersion: 1 };
                if (webGLContextAttributes) {
                    for (var attribute in webGLContextAttributes) {
                        contextAttributes[attribute] = webGLContextAttributes[attribute];
                    }
                }
                if (typeof GL != "undefined") {
                    contextHandle = GL.createContext(canvas, contextAttributes);
                    if (contextHandle) {
                        ctx = GL.getContext(contextHandle).GLctx;
                    }
                }
            }
            else {
                ctx = canvas.getContext("2d");
            } if (!ctx)
                return null; if (setInModule) {
                if (!useWebGL)
                    assert(typeof GLctx == "undefined", "cannot set in module if GLctx is used, but we are a non-GL context that would replace it");
                Module.ctx = ctx;
                if (useWebGL)
                    GL.makeContextCurrent(contextHandle);
                Module.useWebGL = useWebGL;
                Browser.moduleContextCreatedCallbacks.forEach(function (callback) { callback(); });
                Browser.init();
            } return ctx; }, destroyContext: function (canvas, useWebGL, setInModule) { }, fullscreenHandlersInstalled: false, lockPointer: undefined, resizeCanvas: undefined, requestFullscreen: function (lockPointer, resizeCanvas) { Browser.lockPointer = lockPointer; Browser.resizeCanvas = resizeCanvas; if (typeof Browser.lockPointer == "undefined")
                Browser.lockPointer = true; if (typeof Browser.resizeCanvas == "undefined")
                Browser.resizeCanvas = false; var canvas = Module["canvas"]; function fullscreenChange() { Browser.isFullscreen = false; var canvasContainer = canvas.parentNode; if ((document["fullscreenElement"] || document["mozFullScreenElement"] || document["msFullscreenElement"] || document["webkitFullscreenElement"] || document["webkitCurrentFullScreenElement"]) === canvasContainer) {
                canvas.exitFullscreen = Browser.exitFullscreen;
                if (Browser.lockPointer)
                    canvas.requestPointerLock();
                Browser.isFullscreen = true;
                if (Browser.resizeCanvas) {
                    Browser.setFullscreenCanvasSize();
                }
                else {
                    Browser.updateCanvasDimensions(canvas);
                }
            }
            else {
                canvasContainer.parentNode.insertBefore(canvas, canvasContainer);
                canvasContainer.parentNode.removeChild(canvasContainer);
                if (Browser.resizeCanvas) {
                    Browser.setWindowedCanvasSize();
                }
                else {
                    Browser.updateCanvasDimensions(canvas);
                }
            } if (Module["onFullScreen"])
                Module["onFullScreen"](Browser.isFullscreen); if (Module["onFullscreen"])
                Module["onFullscreen"](Browser.isFullscreen); } if (!Browser.fullscreenHandlersInstalled) {
                Browser.fullscreenHandlersInstalled = true;
                document.addEventListener("fullscreenchange", fullscreenChange, false);
                document.addEventListener("mozfullscreenchange", fullscreenChange, false);
                document.addEventListener("webkitfullscreenchange", fullscreenChange, false);
                document.addEventListener("MSFullscreenChange", fullscreenChange, false);
            } var canvasContainer = document.createElement("div"); canvas.parentNode.insertBefore(canvasContainer, canvas); canvasContainer.appendChild(canvas); canvasContainer.requestFullscreen = canvasContainer["requestFullscreen"] || canvasContainer["mozRequestFullScreen"] || canvasContainer["msRequestFullscreen"] || (canvasContainer["webkitRequestFullscreen"] ? function () { return canvasContainer["webkitRequestFullscreen"](Element["ALLOW_KEYBOARD_INPUT"]); } : null) || (canvasContainer["webkitRequestFullScreen"] ? function () { return canvasContainer["webkitRequestFullScreen"](Element["ALLOW_KEYBOARD_INPUT"]); } : null); canvasContainer.requestFullscreen(); }, exitFullscreen: function () { if (!Browser.isFullscreen) {
                return false;
            } var CFS = document["exitFullscreen"] || document["cancelFullScreen"] || document["mozCancelFullScreen"] || document["msExitFullscreen"] || document["webkitCancelFullScreen"] || function () { }; CFS.apply(document, []); return true; }, nextRAF: 0, fakeRequestAnimationFrame: function (func) { var now = Date.now(); if (Browser.nextRAF === 0) {
                Browser.nextRAF = now + 1e3 / 60;
            }
            else {
                while (now + 2 >= Browser.nextRAF) {
                    Browser.nextRAF += 1e3 / 60;
                }
            } var delay = Math.max(Browser.nextRAF - now, 0); setTimeout(func, delay); }, requestAnimationFrame: function (func) { if (typeof requestAnimationFrame == "function") {
                requestAnimationFrame(func);
                return;
            } var RAF = Browser.fakeRequestAnimationFrame; RAF(func); }, safeSetTimeout: function (func, timeout) { return safeSetTimeout(func, timeout); }, safeRequestAnimationFrame: function (func) { return Browser.requestAnimationFrame(function () { callUserCallback(func); }); }, getMimetype: function (name) { return { "jpg": "image/jpeg", "jpeg": "image/jpeg", "png": "image/png", "bmp": "image/bmp", "ogg": "audio/ogg", "wav": "audio/wav", "mp3": "audio/mpeg" }[name.substr(name.lastIndexOf(".") + 1)]; }, getUserMedia: function (func) { if (!window.getUserMedia) {
                window.getUserMedia = navigator["getUserMedia"] || navigator["mozGetUserMedia"];
            } window.getUserMedia(func); }, getMovementX: function (event) { return event["movementX"] || event["mozMovementX"] || event["webkitMovementX"] || 0; }, getMovementY: function (event) { return event["movementY"] || event["mozMovementY"] || event["webkitMovementY"] || 0; }, getMouseWheelDelta: function (event) { var delta = 0; switch (event.type) {
                case "DOMMouseScroll":
                    delta = event.detail / 3;
                    break;
                case "mousewheel":
                    delta = event.wheelDelta / 120;
                    break;
                case "wheel":
                    delta = event.deltaY;
                    switch (event.deltaMode) {
                        case 0:
                            delta /= 100;
                            break;
                        case 1:
                            delta /= 3;
                            break;
                        case 2:
                            delta *= 80;
                            break;
                        default: throw "unrecognized mouse wheel delta mode: " + event.deltaMode;
                    }
                    break;
                default: throw "unrecognized mouse wheel event: " + event.type;
            } return delta; }, mouseX: 0, mouseY: 0, mouseMovementX: 0, mouseMovementY: 0, touches: {}, lastTouches: {}, calculateMouseEvent: function (event) { if (Browser.pointerLock) {
                if (event.type != "mousemove" && "mozMovementX" in event) {
                    Browser.mouseMovementX = Browser.mouseMovementY = 0;
                }
                else {
                    Browser.mouseMovementX = Browser.getMovementX(event);
                    Browser.mouseMovementY = Browser.getMovementY(event);
                }
                if (typeof SDL != "undefined") {
                    Browser.mouseX = SDL.mouseX + Browser.mouseMovementX;
                    Browser.mouseY = SDL.mouseY + Browser.mouseMovementY;
                }
                else {
                    Browser.mouseX += Browser.mouseMovementX;
                    Browser.mouseY += Browser.mouseMovementY;
                }
            }
            else {
                var rect = Module["canvas"].getBoundingClientRect();
                var cw = Module["canvas"].width;
                var ch = Module["canvas"].height;
                var scrollX = typeof window.scrollX != "undefined" ? window.scrollX : window.pageXOffset;
                var scrollY = typeof window.scrollY != "undefined" ? window.scrollY : window.pageYOffset;
                if (event.type === "touchstart" || event.type === "touchend" || event.type === "touchmove") {
                    var touch = event.touch;
                    if (touch === undefined) {
                        return;
                    }
                    var adjustedX = touch.pageX - (scrollX + rect.left);
                    var adjustedY = touch.pageY - (scrollY + rect.top);
                    adjustedX = adjustedX * (cw / rect.width);
                    adjustedY = adjustedY * (ch / rect.height);
                    var coords = { x: adjustedX, y: adjustedY };
                    if (event.type === "touchstart") {
                        Browser.lastTouches[touch.identifier] = coords;
                        Browser.touches[touch.identifier] = coords;
                    }
                    else if (event.type === "touchend" || event.type === "touchmove") {
                        var last = Browser.touches[touch.identifier];
                        if (!last)
                            last = coords;
                        Browser.lastTouches[touch.identifier] = last;
                        Browser.touches[touch.identifier] = coords;
                    }
                    return;
                }
                var x = event.pageX - (scrollX + rect.left);
                var y = event.pageY - (scrollY + rect.top);
                x = x * (cw / rect.width);
                y = y * (ch / rect.height);
                Browser.mouseMovementX = x - Browser.mouseX;
                Browser.mouseMovementY = y - Browser.mouseY;
                Browser.mouseX = x;
                Browser.mouseY = y;
            } }, resizeListeners: [], updateResizeListeners: function () { var canvas = Module["canvas"]; Browser.resizeListeners.forEach(function (listener) { listener(canvas.width, canvas.height); }); }, setCanvasSize: function (width, height, noUpdates) { var canvas = Module["canvas"]; Browser.updateCanvasDimensions(canvas, width, height); if (!noUpdates)
                Browser.updateResizeListeners(); }, windowedWidth: 0, windowedHeight: 0, setFullscreenCanvasSize: function () { if (typeof SDL != "undefined") {
                var flags = HEAPU32[SDL.screen >> 2];
                flags = flags | 8388608;
                HEAP32[SDL.screen >> 2] = flags;
            } Browser.updateCanvasDimensions(Module["canvas"]); Browser.updateResizeListeners(); }, setWindowedCanvasSize: function () { if (typeof SDL != "undefined") {
                var flags = HEAPU32[SDL.screen >> 2];
                flags = flags & ~8388608;
                HEAP32[SDL.screen >> 2] = flags;
            } Browser.updateCanvasDimensions(Module["canvas"]); Browser.updateResizeListeners(); }, updateCanvasDimensions: function (canvas, wNative, hNative) { if (wNative && hNative) {
                canvas.widthNative = wNative;
                canvas.heightNative = hNative;
            }
            else {
                wNative = canvas.widthNative;
                hNative = canvas.heightNative;
            } var w = wNative; var h = hNative; if (Module["forcedAspectRatio"] && Module["forcedAspectRatio"] > 0) {
                if (w / h < Module["forcedAspectRatio"]) {
                    w = Math.round(h * Module["forcedAspectRatio"]);
                }
                else {
                    h = Math.round(w / Module["forcedAspectRatio"]);
                }
            } if ((document["fullscreenElement"] || document["mozFullScreenElement"] || document["msFullscreenElement"] || document["webkitFullscreenElement"] || document["webkitCurrentFullScreenElement"]) === canvas.parentNode && typeof screen != "undefined") {
                var factor = Math.min(screen.width / w, screen.height / h);
                w = Math.round(w * factor);
                h = Math.round(h * factor);
            } if (Browser.resizeCanvas) {
                if (canvas.width != w)
                    canvas.width = w;
                if (canvas.height != h)
                    canvas.height = h;
                if (typeof canvas.style != "undefined") {
                    canvas.style.removeProperty("width");
                    canvas.style.removeProperty("height");
                }
            }
            else {
                if (canvas.width != wNative)
                    canvas.width = wNative;
                if (canvas.height != hNative)
                    canvas.height = hNative;
                if (typeof canvas.style != "undefined") {
                    if (w != wNative || h != hNative) {
                        canvas.style.setProperty("width", w + "px", "important");
                        canvas.style.setProperty("height", h + "px", "important");
                    }
                    else {
                        canvas.style.removeProperty("width");
                        canvas.style.removeProperty("height");
                    }
                }
            } } };
        function _emscripten_call_worker(id, funcName, data, size, callback, arg) { funcName = UTF8ToString(funcName); var info = Browser.workers[id]; var callbackId = -1; if (callback) {
            callbackId = info.callbacks.length;
            info.callbacks.push({ func: getWasmTableEntry(callback), arg: arg });
            info.awaited++;
        } var transferObject = { "funcName": funcName, "callbackId": callbackId, "data": data ? new Uint8Array(HEAPU8.subarray(data, data + size)) : 0 }; if (data) {
            info.worker.postMessage(transferObject, [transferObject.data.buffer]);
        }
        else {
            info.worker.postMessage(transferObject);
        } }
        function _emscripten_create_worker(url) { url = UTF8ToString(url); var id = Browser.workers.length; var info = { worker: new Worker(url), callbacks: [], awaited: 0, buffer: 0, bufferSize: 0 }; info.worker.onmessage = function info_worker_onmessage(msg) { if (ABORT)
            return; var info = Browser.workers[id]; if (!info)
            return; var callbackId = msg.data["callbackId"]; var callbackInfo = info.callbacks[callbackId]; if (!callbackInfo)
            return; if (msg.data["finalResponse"]) {
            info.awaited--;
            info.callbacks[callbackId] = null;
        } var data = msg.data["data"]; if (data) {
            if (!data.byteLength)
                data = new Uint8Array(data);
            if (!info.buffer || info.bufferSize < data.length) {
                if (info.buffer)
                    _free(info.buffer);
                info.bufferSize = data.length;
                info.buffer = _malloc(data.length);
            }
            HEAPU8.set(data, info.buffer);
            callbackInfo.func(info.buffer, data.length, callbackInfo.arg);
        }
        else {
            callbackInfo.func(0, 0, callbackInfo.arg);
        } }; Browser.workers.push(info); return id; }
        function _emscripten_date_now() { return Date.now(); }
        function getHeapMax() { return 2147483648; }
        function _emscripten_get_heap_max() { return getHeapMax(); }
        function _emscripten_memcpy_big(dest, src, num) { HEAPU8.copyWithin(dest, src, src + num); }
        function emscripten_realloc_buffer(size) { var b = wasmMemory.buffer; try {
            wasmMemory.grow(size - b.byteLength + 65535 >>> 16);
            updateMemoryViews();
            return 1;
        }
        catch (e) { } }
        function _emscripten_resize_heap(requestedSize) { var oldSize = HEAPU8.length; requestedSize = requestedSize >>> 0; var maxHeapSize = getHeapMax(); if (requestedSize > maxHeapSize) {
            return false;
        } var alignUp = function (x, multiple) { return x + (multiple - x % multiple) % multiple; }; for (var cutDown = 1; cutDown <= 4; cutDown *= 2) {
            var overGrownHeapSize = oldSize * (1 + .2 / cutDown);
            overGrownHeapSize = Math.min(overGrownHeapSize, requestedSize + 100663296);
            var newSize = Math.min(maxHeapSize, alignUp(Math.max(requestedSize, overGrownHeapSize), 65536));
            var replacement = emscripten_realloc_buffer(newSize);
            if (replacement) {
                return true;
            }
        } return false; }
        var ENV = {};
        function getExecutableName() { return thisProgram || "./this.program"; }
        function getEnvStrings() { if (!getEnvStrings.strings) {
            var lang = (typeof navigator == "object" && navigator.languages && navigator.languages[0] || "C").replace("-", "_") + ".UTF-8";
            var env = { "USER": "web_user", "LOGNAME": "web_user", "PATH": "/", "PWD": "/", "HOME": "/home/web_user", "LANG": lang, "_": getExecutableName() };
            for (var x in ENV) {
                if (ENV[x] === undefined)
                    delete env[x];
                else
                    env[x] = ENV[x];
            }
            var strings = [];
            for (var x in env) {
                strings.push(x + "=" + env[x]);
            }
            getEnvStrings.strings = strings;
        } return getEnvStrings.strings; }
        function writeAsciiToMemory(str, buffer, dontAddNull) { for (var i = 0; i < str.length; ++i) {
            HEAP8[buffer++ >> 0] = str.charCodeAt(i);
        } if (!dontAddNull)
            HEAP8[buffer >> 0] = 0; }
        function _environ_get(__environ, environ_buf) { var bufSize = 0; getEnvStrings().forEach(function (string, i) { var ptr = environ_buf + bufSize; HEAPU32[__environ + i * 4 >> 2] = ptr; writeAsciiToMemory(string, ptr); bufSize += string.length + 1; }); return 0; }
        function _environ_sizes_get(penviron_count, penviron_buf_size) { var strings = getEnvStrings(); HEAPU32[penviron_count >> 2] = strings.length; var bufSize = 0; strings.forEach(function (string) { bufSize += string.length + 1; }); HEAPU32[penviron_buf_size >> 2] = bufSize; return 0; }
        function _proc_exit(code) { EXITSTATUS = code; if (!keepRuntimeAlive()) {
            if (Module["onExit"])
                Module["onExit"](code);
            ABORT = true;
        } quit_(code, new ExitStatus(code)); }
        function exitJS(status, implicit) { EXITSTATUS = status; _proc_exit(status); }
        var _exit = exitJS;
        function _fd_close(fd) { return 52; }
        function _fd_read(fd, iov, iovcnt, pnum) { return 52; }
        function _fd_seek(fd, offset_low, offset_high, whence, newOffset) { return 70; }
        var printCharBuffers = [null, [], []];
        function printChar(stream, curr) { var buffer = printCharBuffers[stream]; if (curr === 0 || curr === 10) {
            (stream === 1 ? out : err)(UTF8ArrayToString(buffer, 0));
            buffer.length = 0;
        }
        else {
            buffer.push(curr);
        } }
        function _fd_write(fd, iov, iovcnt, pnum) { var num = 0; for (var i = 0; i < iovcnt; i++) {
            var ptr = HEAPU32[iov >> 2];
            var len = HEAPU32[iov + 4 >> 2];
            iov += 8;
            for (var j = 0; j < len; j++) {
                printChar(fd, HEAPU8[ptr + j]);
            }
            num += len;
        } HEAPU32[pnum >> 2] = num; return 0; }
        function getRandomDevice() { if (typeof crypto == "object" && typeof crypto["getRandomValues"] == "function") {
            var randomBuffer = new Uint8Array(1);
            return function () { crypto.getRandomValues(randomBuffer); return randomBuffer[0]; };
        }
        else
            return function () { return abort("randomDevice"); }; }
        function _getentropy(buffer, size) { if (!_getentropy.randomDevice) {
            _getentropy.randomDevice = getRandomDevice();
        } for (var i = 0; i < size; i++) {
            HEAP8[buffer + i >> 0] = _getentropy.randomDevice();
        } return 0; }
        function _llvm_eh_typeid_for(type) { return type; }
        function __isLeapYear(year) { return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0); }
        function __arraySum(array, index) { var sum = 0; for (var i = 0; i <= index; sum += array[i++]) { } return sum; }
        var __MONTH_DAYS_LEAP = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        var __MONTH_DAYS_REGULAR = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        function __addDays(date, days) { var newDate = new Date(date.getTime()); while (days > 0) {
            var leap = __isLeapYear(newDate.getFullYear());
            var currentMonth = newDate.getMonth();
            var daysInCurrentMonth = (leap ? __MONTH_DAYS_LEAP : __MONTH_DAYS_REGULAR)[currentMonth];
            if (days > daysInCurrentMonth - newDate.getDate()) {
                days -= daysInCurrentMonth - newDate.getDate() + 1;
                newDate.setDate(1);
                if (currentMonth < 11) {
                    newDate.setMonth(currentMonth + 1);
                }
                else {
                    newDate.setMonth(0);
                    newDate.setFullYear(newDate.getFullYear() + 1);
                }
            }
            else {
                newDate.setDate(newDate.getDate() + days);
                return newDate;
            }
        } return newDate; }
        function intArrayFromString(stringy, dontAddNull, length) { var len = length > 0 ? length : lengthBytesUTF8(stringy) + 1; var u8array = new Array(len); var numBytesWritten = stringToUTF8Array(stringy, u8array, 0, u8array.length); if (dontAddNull)
            u8array.length = numBytesWritten; return u8array; }
        function writeArrayToMemory(array, buffer) { HEAP8.set(array, buffer); }
        function _strftime(s, maxsize, format, tm) { var tm_zone = HEAP32[tm + 40 >> 2]; var date = { tm_sec: HEAP32[tm >> 2], tm_min: HEAP32[tm + 4 >> 2], tm_hour: HEAP32[tm + 8 >> 2], tm_mday: HEAP32[tm + 12 >> 2], tm_mon: HEAP32[tm + 16 >> 2], tm_year: HEAP32[tm + 20 >> 2], tm_wday: HEAP32[tm + 24 >> 2], tm_yday: HEAP32[tm + 28 >> 2], tm_isdst: HEAP32[tm + 32 >> 2], tm_gmtoff: HEAP32[tm + 36 >> 2], tm_zone: tm_zone ? UTF8ToString(tm_zone) : "" }; var pattern = UTF8ToString(format); var EXPANSION_RULES_1 = { "%c": "%a %b %d %H:%M:%S %Y", "%D": "%m/%d/%y", "%F": "%Y-%m-%d", "%h": "%b", "%r": "%I:%M:%S %p", "%R": "%H:%M", "%T": "%H:%M:%S", "%x": "%m/%d/%y", "%X": "%H:%M:%S", "%Ec": "%c", "%EC": "%C", "%Ex": "%m/%d/%y", "%EX": "%H:%M:%S", "%Ey": "%y", "%EY": "%Y", "%Od": "%d", "%Oe": "%e", "%OH": "%H", "%OI": "%I", "%Om": "%m", "%OM": "%M", "%OS": "%S", "%Ou": "%u", "%OU": "%U", "%OV": "%V", "%Ow": "%w", "%OW": "%W", "%Oy": "%y" }; for (var rule in EXPANSION_RULES_1) {
            pattern = pattern.replace(new RegExp(rule, "g"), EXPANSION_RULES_1[rule]);
        } var WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; var MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]; function leadingSomething(value, digits, character) { var str = typeof value == "number" ? value.toString() : value || ""; while (str.length < digits) {
            str = character[0] + str;
        } return str; } function leadingNulls(value, digits) { return leadingSomething(value, digits, "0"); } function compareByDay(date1, date2) { function sgn(value) { return value < 0 ? -1 : value > 0 ? 1 : 0; } var compare; if ((compare = sgn(date1.getFullYear() - date2.getFullYear())) === 0) {
            if ((compare = sgn(date1.getMonth() - date2.getMonth())) === 0) {
                compare = sgn(date1.getDate() - date2.getDate());
            }
        } return compare; } function getFirstWeekStartDate(janFourth) { switch (janFourth.getDay()) {
            case 0: return new Date(janFourth.getFullYear() - 1, 11, 29);
            case 1: return janFourth;
            case 2: return new Date(janFourth.getFullYear(), 0, 3);
            case 3: return new Date(janFourth.getFullYear(), 0, 2);
            case 4: return new Date(janFourth.getFullYear(), 0, 1);
            case 5: return new Date(janFourth.getFullYear() - 1, 11, 31);
            case 6: return new Date(janFourth.getFullYear() - 1, 11, 30);
        } } function getWeekBasedYear(date) { var thisDate = __addDays(new Date(date.tm_year + 1900, 0, 1), date.tm_yday); var janFourthThisYear = new Date(thisDate.getFullYear(), 0, 4); var janFourthNextYear = new Date(thisDate.getFullYear() + 1, 0, 4); var firstWeekStartThisYear = getFirstWeekStartDate(janFourthThisYear); var firstWeekStartNextYear = getFirstWeekStartDate(janFourthNextYear); if (compareByDay(firstWeekStartThisYear, thisDate) <= 0) {
            if (compareByDay(firstWeekStartNextYear, thisDate) <= 0) {
                return thisDate.getFullYear() + 1;
            }
            return thisDate.getFullYear();
        } return thisDate.getFullYear() - 1; } var EXPANSION_RULES_2 = { "%a": function (date) { return WEEKDAYS[date.tm_wday].substring(0, 3); }, "%A": function (date) { return WEEKDAYS[date.tm_wday]; }, "%b": function (date) { return MONTHS[date.tm_mon].substring(0, 3); }, "%B": function (date) { return MONTHS[date.tm_mon]; }, "%C": function (date) { var year = date.tm_year + 1900; return leadingNulls(year / 100 | 0, 2); }, "%d": function (date) { return leadingNulls(date.tm_mday, 2); }, "%e": function (date) { return leadingSomething(date.tm_mday, 2, " "); }, "%g": function (date) { return getWeekBasedYear(date).toString().substring(2); }, "%G": function (date) { return getWeekBasedYear(date); }, "%H": function (date) { return leadingNulls(date.tm_hour, 2); }, "%I": function (date) { var twelveHour = date.tm_hour; if (twelveHour == 0)
                twelveHour = 12;
            else if (twelveHour > 12)
                twelveHour -= 12; return leadingNulls(twelveHour, 2); }, "%j": function (date) { return leadingNulls(date.tm_mday + __arraySum(__isLeapYear(date.tm_year + 1900) ? __MONTH_DAYS_LEAP : __MONTH_DAYS_REGULAR, date.tm_mon - 1), 3); }, "%m": function (date) { return leadingNulls(date.tm_mon + 1, 2); }, "%M": function (date) { return leadingNulls(date.tm_min, 2); }, "%n": function () { return "\n"; }, "%p": function (date) { if (date.tm_hour >= 0 && date.tm_hour < 12) {
                return "AM";
            } return "PM"; }, "%S": function (date) { return leadingNulls(date.tm_sec, 2); }, "%t": function () { return "\t"; }, "%u": function (date) { return date.tm_wday || 7; }, "%U": function (date) { var days = date.tm_yday + 7 - date.tm_wday; return leadingNulls(Math.floor(days / 7), 2); }, "%V": function (date) { var val = Math.floor((date.tm_yday + 7 - (date.tm_wday + 6) % 7) / 7); if ((date.tm_wday + 371 - date.tm_yday - 2) % 7 <= 2) {
                val++;
            } if (!val) {
                val = 52;
                var dec31 = (date.tm_wday + 7 - date.tm_yday - 1) % 7;
                if (dec31 == 4 || dec31 == 5 && __isLeapYear(date.tm_year % 400 - 1)) {
                    val++;
                }
            }
            else if (val == 53) {
                var jan1 = (date.tm_wday + 371 - date.tm_yday) % 7;
                if (jan1 != 4 && (jan1 != 3 || !__isLeapYear(date.tm_year)))
                    val = 1;
            } return leadingNulls(val, 2); }, "%w": function (date) { return date.tm_wday; }, "%W": function (date) { var days = date.tm_yday + 7 - (date.tm_wday + 6) % 7; return leadingNulls(Math.floor(days / 7), 2); }, "%y": function (date) { return (date.tm_year + 1900).toString().substring(2); }, "%Y": function (date) { return date.tm_year + 1900; }, "%z": function (date) { var off = date.tm_gmtoff; var ahead = off >= 0; off = Math.abs(off) / 60; off = off / 60 * 100 + off % 60; return (ahead ? "+" : "-") + String("0000" + off).slice(-4); }, "%Z": function (date) { return date.tm_zone; }, "%%": function () { return "%"; } }; pattern = pattern.replace(/%%/g, "\0\0"); for (var rule in EXPANSION_RULES_2) {
            if (pattern.includes(rule)) {
                pattern = pattern.replace(new RegExp(rule, "g"), EXPANSION_RULES_2[rule](date));
            }
        } pattern = pattern.replace(/\0\0/g, "%"); var bytes = intArrayFromString(pattern, false); if (bytes.length > maxsize) {
            return 0;
        } writeArrayToMemory(bytes, s); return bytes.length - 1; }
        function _strftime_l(s, maxsize, format, tm, loc) { return _strftime(s, maxsize, format, tm); }
        function uleb128Encode(n, target) { if (n < 128) {
            target.push(n);
        }
        else {
            target.push(n % 128 | 128, n >> 7);
        } }
        function sigToWasmTypes(sig) { var typeNames = { "i": "i32", "j": "i32", "f": "f32", "d": "f64", "p": "i32" }; var type = { parameters: [], results: sig[0] == "v" ? [] : [typeNames[sig[0]]] }; for (var i = 1; i < sig.length; ++i) {
            type.parameters.push(typeNames[sig[i]]);
            if (sig[i] === "j") {
                type.parameters.push("i32");
            }
        } return type; }
        function generateFuncType(sig, target) { var sigRet = sig.slice(0, 1); var sigParam = sig.slice(1); var typeCodes = { "i": 127, "p": 127, "j": 126, "f": 125, "d": 124 }; target.push(96); uleb128Encode(sigParam.length, target); for (var i = 0; i < sigParam.length; ++i) {
            target.push(typeCodes[sigParam[i]]);
        } if (sigRet == "v") {
            target.push(0);
        }
        else {
            target.push(1, typeCodes[sigRet]);
        } }
        function convertJsFunctionToWasm(func, sig) { if (typeof WebAssembly.Function == "function") {
            return new WebAssembly.Function(sigToWasmTypes(sig), func);
        } var typeSectionBody = [1]; generateFuncType(sig, typeSectionBody); var bytes = [0, 97, 115, 109, 1, 0, 0, 0, 1]; uleb128Encode(typeSectionBody.length, bytes); bytes.push.apply(bytes, typeSectionBody); bytes.push(2, 7, 1, 1, 101, 1, 102, 0, 0, 7, 5, 1, 1, 102, 0, 0); var module = new WebAssembly.Module(new Uint8Array(bytes)); var instance = new WebAssembly.Instance(module, { "e": { "f": func } }); var wrappedFunc = instance.exports["f"]; return wrappedFunc; }
        function updateTableMap(offset, count) { if (functionsInTableMap) {
            for (var i = offset; i < offset + count; i++) {
                var item = getWasmTableEntry(i);
                if (item) {
                    functionsInTableMap.set(item, i);
                }
            }
        } }
        var functionsInTableMap = undefined;
        function getFunctionAddress(func) { if (!functionsInTableMap) {
            functionsInTableMap = new WeakMap;
            updateTableMap(0, wasmTable.length);
        } return functionsInTableMap.get(func) || 0; }
        var freeTableIndexes = [];
        function getEmptyTableSlot() { if (freeTableIndexes.length) {
            return freeTableIndexes.pop();
        } try {
            wasmTable.grow(1);
        }
        catch (err) {
            if (!(err instanceof RangeError)) {
                throw err;
            }
            throw "Unable to grow wasm table. Set ALLOW_TABLE_GROWTH.";
        } return wasmTable.length - 1; }
        function setWasmTableEntry(idx, func) { wasmTable.set(idx, func); wasmTableMirror[idx] = wasmTable.get(idx); }
        function addFunction(func, sig) { var rtn = getFunctionAddress(func); if (rtn) {
            return rtn;
        } var ret = getEmptyTableSlot(); try {
            setWasmTableEntry(ret, func);
        }
        catch (err) {
            if (!(err instanceof TypeError)) {
                throw err;
            }
            var wrapped = convertJsFunctionToWasm(func, sig);
            setWasmTableEntry(ret, wrapped);
        } functionsInTableMap.set(func, ret); return ret; }
        Module["requestFullscreen"] = function Module_requestFullscreen(lockPointer, resizeCanvas) { Browser.requestFullscreen(lockPointer, resizeCanvas); };
        Module["requestAnimationFrame"] = function Module_requestAnimationFrame(func) { Browser.requestAnimationFrame(func); };
        Module["setCanvasSize"] = function Module_setCanvasSize(width, height, noUpdates) { Browser.setCanvasSize(width, height, noUpdates); };
        Module["pauseMainLoop"] = function Module_pauseMainLoop() { Browser.mainLoop.pause(); };
        Module["resumeMainLoop"] = function Module_resumeMainLoop() { Browser.mainLoop.resume(); };
        Module["getUserMedia"] = function Module_getUserMedia() { Browser.getUserMedia(); };
        Module["createContext"] = function Module_createContext(canvas, useWebGL, setInModule, webGLContextAttributes) { return Browser.createContext(canvas, useWebGL, setInModule, webGLContextAttributes); };
        var preloadedImages = {};
        var preloadedAudios = {};
        var wasmImports = { "__cxa_begin_catch": ___cxa_begin_catch, "__cxa_end_catch": ___cxa_end_catch, "__cxa_find_matching_catch_2": ___cxa_find_matching_catch_2, "__cxa_find_matching_catch_3": ___cxa_find_matching_catch_3, "__cxa_find_matching_catch_4": ___cxa_find_matching_catch_4, "__cxa_rethrow": ___cxa_rethrow, "__cxa_throw": ___cxa_throw, "__dlsym": ___dlsym, "__resumeException": ___resumeException, "__syscall_fcntl64": ___syscall_fcntl64, "__syscall_ioctl": ___syscall_ioctl, "__syscall_openat": ___syscall_openat, "_emscripten_get_now_is_monotonic": __emscripten_get_now_is_monotonic, "abort": _abort, "dlopen": _dlopen, "emscripten_asm_const_int": _emscripten_asm_const_int, "emscripten_call_worker": _emscripten_call_worker, "emscripten_create_worker": _emscripten_create_worker, "emscripten_date_now": _emscripten_date_now, "emscripten_get_heap_max": _emscripten_get_heap_max, "emscripten_get_now": _emscripten_get_now, "emscripten_memcpy_big": _emscripten_memcpy_big, "emscripten_resize_heap": _emscripten_resize_heap, "environ_get": _environ_get, "environ_sizes_get": _environ_sizes_get, "exit": _exit, "fd_close": _fd_close, "fd_read": _fd_read, "fd_seek": _fd_seek, "fd_write": _fd_write, "getentropy": _getentropy, "invoke_i": invoke_i, "invoke_ii": invoke_ii, "invoke_iidddddi": invoke_iidddddi, "invoke_iii": invoke_iii, "invoke_iiiddddd": invoke_iiiddddd, "invoke_iiii": invoke_iiii, "invoke_iiiii": invoke_iiiii, "invoke_iiiiii": invoke_iiiiii, "invoke_iiiiiii": invoke_iiiiiii, "invoke_iiiiiiiiiiiii": invoke_iiiiiiiiiiiii, "invoke_iiijii": invoke_iiijii, "invoke_v": invoke_v, "invoke_vi": invoke_vi, "invoke_vii": invoke_vii, "invoke_viiidi": invoke_viiidi, "invoke_viiiiii": invoke_viiiiii, "llvm_eh_typeid_for": _llvm_eh_typeid_for, "strftime_l": _strftime_l };
        var asm = createWasm();
        var ___wasm_call_ctors = function () { return (___wasm_call_ctors = Module["asm"]["__wasm_call_ctors"]).apply(null, arguments); };
        var _init_tracker = Module["_init_tracker"] = function () { return (_init_tracker = Module["_init_tracker"] = Module["asm"]["init_tracker"]).apply(null, arguments); };
        var _init_face_detector = Module["_init_face_detector"] = function () { return (_init_face_detector = Module["_init_face_detector"] = Module["asm"]["init_face_detector"]).apply(null, arguments); };
        var _get_image_offset = Module["_get_image_offset"] = function () { return (_get_image_offset = Module["_get_image_offset"] = Module["asm"]["get_image_offset"]).apply(null, arguments); };
        var _detect_landmarks = Module["_detect_landmarks"] = function () { return (_detect_landmarks = Module["_detect_landmarks"] = Module["asm"]["detect_landmarks"]).apply(null, arguments); };
        var _get_landmarks_offset = Module["_get_landmarks_offset"] = function () { return (_get_landmarks_offset = Module["_get_landmarks_offset"] = Module["asm"]["get_landmarks_offset"]).apply(null, arguments); };
        var _add_training = Module["_add_training"] = function () { return (_add_training = Module["_add_training"] = Module["asm"]["add_training"]).apply(null, arguments); };
        var _train = Module["_train"] = function () { return (_train = Module["_train"] = Module["asm"]["train"]).apply(null, arguments); };
        var _reset_training = Module["_reset_training"] = function () { return (_reset_training = Module["_reset_training"] = Module["asm"]["reset_training"]).apply(null, arguments); };
        var _update = Module["_update"] = function () { return (_update = Module["_update"] = Module["asm"]["update"]).apply(null, arguments); };
        var _get_x = Module["_get_x"] = function () { return (_get_x = Module["_get_x"] = Module["asm"]["get_x"]).apply(null, arguments); };
        var _get_y = Module["_get_y"] = function () { return (_get_y = Module["_get_y"] = Module["asm"]["get_y"]).apply(null, arguments); };
        var _get_mod_x = Module["_get_mod_x"] = function () { return (_get_mod_x = Module["_get_mod_x"] = Module["asm"]["get_mod_x"]).apply(null, arguments); };
        var _get_mod_y = Module["_get_mod_y"] = function () { return (_get_mod_y = Module["_get_mod_y"] = Module["asm"]["get_mod_y"]).apply(null, arguments); };
        var _get_head_x = Module["_get_head_x"] = function () { return (_get_head_x = Module["_get_head_x"] = Module["asm"]["get_head_x"]).apply(null, arguments); };
        var _get_head_y = Module["_get_head_y"] = function () { return (_get_head_y = Module["_get_head_y"] = Module["asm"]["get_head_y"]).apply(null, arguments); };
        var _get_timestamp = Module["_get_timestamp"] = function () { return (_get_timestamp = Module["_get_timestamp"] = Module["asm"]["get_timestamp"]).apply(null, arguments); };
        var _get_duration = Module["_get_duration"] = function () { return (_get_duration = Module["_get_duration"] = Module["asm"]["get_duration"]).apply(null, arguments); };
        var _get_model = Module["_get_model"] = function () { return (_get_model = Module["_get_model"] = Module["asm"]["get_model"]).apply(null, arguments); };
        var _set_model = Module["_set_model"] = function () { return (_set_model = Module["_set_model"] = Module["asm"]["set_model"]).apply(null, arguments); };
        var _get_params = Module["_get_params"] = function () { return (_get_params = Module["_get_params"] = Module["asm"]["get_params"]).apply(null, arguments); };
        var _set_params = Module["_set_params"] = function () { return (_set_params = Module["_set_params"] = Module["asm"]["set_params"]).apply(null, arguments); };
        var _free = Module["_free"] = function () { return (_free = Module["_free"] = Module["asm"]["free"]).apply(null, arguments); };
        var _malloc = Module["_malloc"] = function () { return (_malloc = Module["_malloc"] = Module["asm"]["malloc"]).apply(null, arguments); };
        var ___cxa_free_exception = function () { return (___cxa_free_exception = Module["asm"]["__cxa_free_exception"]).apply(null, arguments); };
        var ___errno_location = function () { return (___errno_location = Module["asm"]["__errno_location"]).apply(null, arguments); };
        var ___dl_seterr = function () { return (___dl_seterr = Module["asm"]["__dl_seterr"]).apply(null, arguments); };
        var setTempRet0 = function () { return (setTempRet0 = Module["asm"]["setTempRet0"]).apply(null, arguments); };
        var stackSave = function () { return (stackSave = Module["asm"]["stackSave"]).apply(null, arguments); };
        var stackRestore = function () { return (stackRestore = Module["asm"]["stackRestore"]).apply(null, arguments); };
        var stackAlloc = function () { return (stackAlloc = Module["asm"]["stackAlloc"]).apply(null, arguments); };
        var ___cxa_can_catch = function () { return (___cxa_can_catch = Module["asm"]["__cxa_can_catch"]).apply(null, arguments); };
        var ___cxa_is_pointer_type = function () { return (___cxa_is_pointer_type = Module["asm"]["__cxa_is_pointer_type"]).apply(null, arguments); };
        var dynCall_ji = Module["dynCall_ji"] = function () { return (dynCall_ji = Module["dynCall_ji"] = Module["asm"]["dynCall_ji"]).apply(null, arguments); };
        var dynCall_jjj = Module["dynCall_jjj"] = function () { return (dynCall_jjj = Module["dynCall_jjj"] = Module["asm"]["dynCall_jjj"]).apply(null, arguments); };
        var dynCall_iiiijj = Module["dynCall_iiiijj"] = function () { return (dynCall_iiiijj = Module["dynCall_iiiijj"] = Module["asm"]["dynCall_iiiijj"]).apply(null, arguments); };
        var dynCall_viijj = Module["dynCall_viijj"] = function () { return (dynCall_viijj = Module["dynCall_viijj"] = Module["asm"]["dynCall_viijj"]).apply(null, arguments); };
        var dynCall_viiijjjj = Module["dynCall_viiijjjj"] = function () { return (dynCall_viiijjjj = Module["dynCall_viiijjjj"] = Module["asm"]["dynCall_viiijjjj"]).apply(null, arguments); };
        var dynCall_iiijii = Module["dynCall_iiijii"] = function () { return (dynCall_iiijii = Module["dynCall_iiijii"] = Module["asm"]["dynCall_iiijii"]).apply(null, arguments); };
        var dynCall_iiiji = Module["dynCall_iiiji"] = function () { return (dynCall_iiiji = Module["dynCall_iiiji"] = Module["asm"]["dynCall_iiiji"]).apply(null, arguments); };
        var dynCall_iiij = Module["dynCall_iiij"] = function () { return (dynCall_iiij = Module["dynCall_iiij"] = Module["asm"]["dynCall_iiij"]).apply(null, arguments); };
        var dynCall_iijii = Module["dynCall_iijii"] = function () { return (dynCall_iijii = Module["dynCall_iijii"] = Module["asm"]["dynCall_iijii"]).apply(null, arguments); };
        var dynCall_iiijiji = Module["dynCall_iiijiji"] = function () { return (dynCall_iiijiji = Module["dynCall_iiijiji"] = Module["asm"]["dynCall_iiijiji"]).apply(null, arguments); };
        var dynCall_iijjiiii = Module["dynCall_iijjiiii"] = function () { return (dynCall_iijjiiii = Module["dynCall_iijjiiii"] = Module["asm"]["dynCall_iijjiiii"]).apply(null, arguments); };
        var dynCall_jiji = Module["dynCall_jiji"] = function () { return (dynCall_jiji = Module["dynCall_jiji"] = Module["asm"]["dynCall_jiji"]).apply(null, arguments); };
        var dynCall_viijii = Module["dynCall_viijii"] = function () { return (dynCall_viijii = Module["dynCall_viijii"] = Module["asm"]["dynCall_viijii"]).apply(null, arguments); };
        var dynCall_iiiiij = Module["dynCall_iiiiij"] = function () { return (dynCall_iiiiij = Module["dynCall_iiiiij"] = Module["asm"]["dynCall_iiiiij"]).apply(null, arguments); };
        var dynCall_iiiiijj = Module["dynCall_iiiiijj"] = function () { return (dynCall_iiiiijj = Module["dynCall_iiiiijj"] = Module["asm"]["dynCall_iiiiijj"]).apply(null, arguments); };
        var dynCall_iiiiiijj = Module["dynCall_iiiiiijj"] = function () { return (dynCall_iiiiiijj = Module["dynCall_iiiiiijj"] = Module["asm"]["dynCall_iiiiiijj"]).apply(null, arguments); };
        function invoke_vi(index, a1) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_ii(index, a1) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iidddddi(index, a1, a2, a3, a4, a5, a6, a7) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iii(index, a1, a2) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_vii(index, a1, a2) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1, a2);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiddddd(index, a1, a2, a3, a4, a5, a6, a7) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiii(index, a1, a2, a3) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_viiidi(index, a1, a2, a3, a4, a5) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1, a2, a3, a4, a5);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiiiiiiiiiii(index, a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_viiiiii(index, a1, a2, a3, a4, a5, a6) { var sp = stackSave(); try {
            getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiiiii(index, a1, a2, a3, a4, a5, a6) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5, a6);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiiii(index, a1, a2, a3, a4, a5) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4, a5);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiiii(index, a1, a2, a3, a4) { var sp = stackSave(); try {
            return getWasmTableEntry(index)(a1, a2, a3, a4);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_v(index) { var sp = stackSave(); try {
            getWasmTableEntry(index)();
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_i(index) { var sp = stackSave(); try {
            return getWasmTableEntry(index)();
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        function invoke_iiijii(index, a1, a2, a3, a4, a5, a6) { var sp = stackSave(); try {
            return dynCall_iiijii(index, a1, a2, a3, a4, a5, a6);
        }
        catch (e) {
            stackRestore(sp);
            if (e !== e + 0)
                throw e;
            _setThrew(1, 0);
        } }
        Module["addFunction"] = addFunction;
        var calledRun;
        dependenciesFulfilled = function runCaller() { if (!calledRun)
            run(); if (!calledRun)
            dependenciesFulfilled = runCaller; };
        function run() { if (runDependencies > 0) {
            return;
        } preRun(); if (runDependencies > 0) {
            return;
        } function doRun() { if (calledRun)
            return; calledRun = true; Module["calledRun"] = true; if (ABORT)
            return; initRuntime(); readyPromiseResolve(Module); if (Module["onRuntimeInitialized"])
            Module["onRuntimeInitialized"](); postRun(); } if (Module["setStatus"]) {
            Module["setStatus"]("Running...");
            setTimeout(function () { setTimeout(function () { Module["setStatus"](""); }, 1); doRun(); }, 1);
        }
        else {
            doRun();
        } }
        if (Module["preInit"]) {
            if (typeof Module["preInit"] == "function")
                Module["preInit"] = [Module["preInit"]];
            while (Module["preInit"].length > 0) {
                Module["preInit"].pop()();
            }
        }
        run();
        return Module.ready;
    });
})();
exports.default = Module;
