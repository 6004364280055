export type ErrorFormat = {
  internalMessage: string;
  safeMessage: string;
};

const GENERIC = "Something went wrong. Please try again.";

const errorFormatFactory = (internalMessage: string, safeMessage?: string) => {
  const fmt: ErrorFormat = {
    internalMessage,
    safeMessage: safeMessage || internalMessage,
  };
  return fmt;
};

export enum ErrorClass {
  INCORRECT_PASSWORD,
  TRACKER_DIV,
  CALIBRATION_DIV,
  VALIDATION_DIV,
  INIT_TIMEOUT,
  CALIBRATION_TIMEOUT,
  VALIDATION_TIMEOUT,
  EXTENSION_COMMS_FAIL,
  MISSING_MODEL,
  CAMERA_PERMISSIONS,
  VALIDATION_FAIL
}

class ErrorFormatter {
  KNOWN_ERRORS: Record<ErrorClass, ErrorFormat>;
  constructor() {
    this.KNOWN_ERRORS = {
      [ErrorClass.INCORRECT_PASSWORD]: errorFormatFactory("Incorrect password"),
      [ErrorClass.TRACKER_DIV]: errorFormatFactory(
        "Unable to reference tracker div",
        GENERIC
      ),
      [ErrorClass.CALIBRATION_DIV]: errorFormatFactory(
        "Unable to reference calibration div",
        GENERIC
      ),
      [ErrorClass.VALIDATION_DIV]: errorFormatFactory(
        "Unable to reference validation div",
        GENERIC
      ),
      [ErrorClass.INIT_TIMEOUT]: errorFormatFactory(
        "init timed out",
        "Failed to start eyetracking software!"
      ),
      [ErrorClass.CALIBRATION_TIMEOUT]: errorFormatFactory(
        "calibration timed out",
        "Failed to calibrate!"
      ),
      [ErrorClass.VALIDATION_TIMEOUT]: errorFormatFactory(
        "validation timed out",
        "Failed to validate!"
      ),
      [ErrorClass.EXTENSION_COMMS_FAIL]: errorFormatFactory(
        "Unable to communicate with extension!"
      ),
      [ErrorClass.MISSING_MODEL]: errorFormatFactory(
        "Unable to send broker data to camera",
        GENERIC
      ),
      [ErrorClass.CAMERA_PERMISSIONS]: errorFormatFactory(
        "camera permissions not given",
        "Please try to start the session again once camera permissions have been granted."
      ),
      [ErrorClass.VALIDATION_FAIL]: errorFormatFactory(
        "Poor validation results",
        "The validation results were not accurate enough to start a session. Please calibrate again."
      )
    };
  }

  getError = (eclass: ErrorClass): string => {
    return this.KNOWN_ERRORS[eclass].internalMessage;
  };

  formatError = (error?: any): string => {
    if (typeof error === "string") {
      const found = Object.values(this.KNOWN_ERRORS).find(
        (ke) => ke.internalMessage === error
      );
      if (found) {
        return found.safeMessage;
      }
    }

    return GENERIC;
  };
}

export default new ErrorFormatter();
