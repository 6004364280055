import React from "react";
import { PanelMessages } from "@lumen-developer/lumen-common-js/esm/panel/messages";
import { startWildRequest } from "../../../api";
import { SessionSectionProps } from "../session";
import ErrorFormatter, { ErrorClass } from "../../../utils/errorFormat";

interface SessionExternalProps extends SessionSectionProps {
  panelist: string;
  tmpSessionId: string;
  panelistId: number;
  accessCode: string;
}
export const SessionExternal = ({
  broker,
  setSessionState,
  panelist,
  tmpSessionId,
  panelistId,
  accessCode,
  errorHandle
}: SessionExternalProps) => {
  const currentDate = new Date();
  const sessionEndDate = new Date(
    currentDate.setMinutes(currentDate.getMinutes() + 10)
  );

  const start = async () => {
    try {
      await startWildRequest(accessCode, tmpSessionId, panelistId);
      broker.saveModel("model", "errorCorrection");
      if (!!broker.trackersController) {
        broker.turnOffCamera();
      }
      const model = localStorage.getItem("model");
      const errorCorrection = localStorage.getItem("errorCorrection");
      const extensionId = process.env.EXTENSION_ID;

      if (model && errorCorrection) {
        console.log("sending model to extension...");
        let m: PanelMessages.InitSession = {
          type: PanelMessages.ExternalMessageType.InitSession,
          data: {
            broker: {
              model,
              errorCorrection,
            },
            panelist: panelist,
            panelistId: panelistId,
            tmpSessionId: tmpSessionId,
            // sessionLength: 10000, // 10 seconds
            sessionLength: 600000, // TODO: SHOULD BE FROM STATE,
          },
        };
        chrome.runtime.sendMessage(extensionId, m, (_r) => {
          // TODO: BETTER HANDLE SESSION START REDIRECT
          window.location.href = "https://google.com/";
        });
      } else {
        throw ErrorFormatter.getError(ErrorClass.MISSING_MODEL)
      }
    } catch (e) {
      errorHandle(ErrorFormatter.formatError(e))
    }
  };

  // const debug = async () => {
  //   try {
  //     broker.saveModel("model", "errorCorrection");
  //     if (!!broker.trackersController) {
  //       broker.turnOffCamera();
  //     }
  //     const model = localStorage.getItem("model");
  //     const errorCorrection = localStorage.getItem("errorCorrection");
  //     const extensionId = process.env.EXTENSION_ID;

  //     if (model && errorCorrection) {
  //       console.log("sending model to extension...");
  //       let m: PanelMessages.InitSession = {
  //         type: PanelMessages.ExternalMessageType.InitSession,
  //         data: {
  //           broker: {
  //             model,
  //             errorCorrection,
  //           },
  //           panelist: panelist,
  //           panelistId: panelistId,
  //           tmpSessionId: tmpSessionId,
  //           // sessionLength: 10000, // 10 seconds
  //           sessionLength: 200000, // TODO: SHOULD BE FROM STATE,
  //         },
  //       };
  //       chrome.runtime.sendMessage(extensionId, m, (_r) => {
  //         // TODO: BETTER HANDLE SESSION START REDIRECT
  //         // window.location.href = "https://google.com/";
  //       });
  //     } else {
  //       throw ErrorFormatter.getError(ErrorClass.MISSING_MODEL)
  //     }
  //   } catch (e) {
  //     errorHandle(ErrorFormatter.formatError(e))
  //   }
  // };

  return (
    <>
      <p className="font-semibold text-lg mb-10">How to earn points in your session?</p>
      <ol className="mb-10 list-decimal list-inside">
        <li>You will be redirected to a random website</li>
        <li>For the duration of the session browse the internet as you usually would</li>
        <li>
          After 10 minutes you will be automatically redirected in a new tab to a validation screen
        </li>
        <li>
          Complete a successful validation in order to secure your points
        </li>
      </ol>
      {/* TODO: BETTER DATE FORMATTING */}
      <p className="mb-10">
        Your session will last 10 minutes.
      </p>
      <button
        onClick={start}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Start Session
      </button>
      {/* {process.env.NODE_ENV === "local" ? <button
        onClick={debug}
        className="bg-secondary hover:bg-secondary-darken text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        DEBUG SEND TO EXTENSION
      </button> : null} */}
    </>
  );
};
